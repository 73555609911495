/*
 * @Author: js
 * @Date: 2021-10-21 14:39:34
 * @LastEditors: js
 * @LastEditTime: 2022-07-12 10:02:46
 * @Description: desc
 * @FilePath: /zktree_official_website/src/config/index.js
 */
export const ICP = process.env.VUE_APP_ICP;
export const APPID = process.env.VUE_APP_APPID;
export const REDIRECTURI = process.env.VUE_APP_REDIRECTURI;
