/*
 * @Author: js
 * @Date: 2021-10-21 14:39:34
 * @LastEditors: js
 * @LastEditTime: 2022-07-13 13:57:45
 * @Description: desc
 * @FilePath: /zktree_official_website/src/main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import "minireset.css";
import Vant from "vant";
// import 'vant/lib/index.css';

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Vue.use(VueAwesomeSwiper, /* { default global options } */ )
// import {
//   swiper,
//   swiperSlide
// } from 'vue-awesome-swiper';
// import 'swiper/dist/css/swiper.css';

// import 'bootstrap/dist/css/bootstrap.css';
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import {
  Alert,
  MessageBox,
  Container,
  Header,
  Main,
  Footer,
  Menu,
  MenuItem,
  Row,
  Col,
  Loading,
  Icon,
} from "element-ui";
// import VueCompositionAPI from '@vue/composition-api'
// Vue.use(VueCompositionAPI)
Vue.use(Vant);
// Vue.use(swiper);
// Vue.use(swiperSlide);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Alert);
Vue.use(Row);
Vue.use(Col);
// Vue.use(Loading);
Vue.use(Icon);

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$loading = Loading;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
