<template>
	<div class="contact">
		<BannerContact></BannerContact>
	</div>
</template>

<script>
	import BannerContact from "@/components/banner/BannerContact.vue";
	export default {
		name: 'Contact',
		components: {
			BannerContact
		}
	}
</script>

<style>

</style>
