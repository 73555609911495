<!--
 * @Author: js
 * @Date: 2022-07-20 11:05:01
 * @LastEditors: js
 * @LastEditTime: 2022-07-27 16:40:26
 * @Description: desc
 * @FilePath: /zktree_official_website/src/components/index/ProvinceNews.vue
-->
<template>
  <div class="container-index province-news" v-if="news.length">
    <div class="title">各省动态信息</div>
    <div class="news-list">
      <el-row>
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          class="col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-for="item of news"
          :key="item.id"
        >
          <a
            class="col-sm-12 col-md-6 col-lg-6 col-xl-6"
            @click.prevent="goNews(item)"
            :href="'/news?type=news&id=' + item.id"
          >
            <span class="first">{{ item.title }}</span>
            <span class="last">{{ item.gmtUpdate.substr(0, 10) }}</span>
          </a>
        </el-col>
        <template v-if="isShow">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
            class="col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-for="item of newsRest"
            :key="item.id"
          >
            <a
              @click.prevent="goNews(item)"
              href="'/news?type=news&id=' + item.id"
            >
              <span class="first">{{ item.title }}</span>
              <span class="last">{{ item.gmtUpdate.substr(0, 10) }}</span>
            </a>
          </el-col>
        </template>
      </el-row>
    </div>
    <div class="more" @click="showMore" v-if="newsRest.length">
      <div class="green">{{ isShow ? '收起' : '查看更多' }}</div>
      <img class="icon-img" src="@/assets/images/icon-green-b.png" alt="icon" />
    </div>
  </div>
</template>
<script>
import request from '@/utils/request.js'
export default {
  name: 'ProvinceNews',
  data() {
    return {
      news: [],
      newsRest: [],
      isShow: false,
      showMax: 8,
    }
  },
  created() {
    this.init()
  },
  methods: {
    showMore(type) {
      this.isShow = !this.isShow
      console.log(type)
    },
    init() {
      request
        .getPageBreakingNewsList({
          size: 20,
          current: 1,
        })
        .then((res) => {
          console.log(res)
          this.news = res.data.result.records
          this.news = this.news.slice(0, this.showMax)
          this.newsRest = res.data.result.records.slice(this.showMax)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    goNews(row) {
      this.$store.commit('setNews', row)
      this.$router.push({
        path: '/news',
        query: {
          id: row.id,
          type: 'news',
        },
      })
    },
  },
}
</script>
<style lang="scss">
.province-news {
  padding-top: 80px;
  padding-bottom: 0;
  background-color: #fff;
  min-width: 320px;
  text-align: center;
  // background: #000;
  div.title {
    font-size: 36px;
    color: #333;
    text-align: center;
    font-weight: normal;
    background-color: #fff;
  }
  .news-list {
    padding: 20px 0;
    text-align: left;
  }
  div.news-list a {
    // display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    width: 100%;
    text-align: left;
    font-size: 1.2em;
    color: #21a452;
    .first {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      padding-right: 10px;
    }
    .last {
      font-size: 14px;
    }
  }
  div.more {
    cursor: pointer;
    div.green {
      color: #21a452;
    }
  }
  .icon-img {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
}
</style>
