import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import About from "../views/About.vue";
import store from "@/store";
// import BasicLayout from "@/components/BasicLayout";

Vue.use(VueRouter);

const routes = [
  {
    // path: "/index",
    path: "/",
    // component: BasicLayout,
    // component: () => import("../views/Home.vue"),
    components: {
      default: Home,
      // default: () => import("../views/Home.vue"),
    },
    // children: [
    //   {
    //     path: "",
    //     name: "home",
    //     component: () => import("../views/Home.vue"),
    //   },
    // ],
  },
  // {
  //   path: "/",
  //   redirect: {
  //     name: "home",
  //   },
  // },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
    // component: () => import("../views/About.vue"),
    component: About,
  },
  {
    path: "/province",
    name: "province",
    // component: () => import( /* webpackChunkName: "province" */ '../views/ProvinceInfo.vue')
    component: () => import("../views/PrivinceInfo.vue"),
  },
  {
    path: "/bookstore",
    name: "bookstore",
    // component: () => import( /* webpackChunkName: "bookstore" */ '../views/BookStore.vue')
    component: () => import("../views/BookStore.vue"),
  },
  {
    path: "/eduonline",
    name: "eduonline",
    // component: () => import( /* webpackChunkName: "eduonline" */ '../views/EduOnline.vue')
    component: () => import("../views/EduOnline.vue"),
  },
  {
    path: "/devprocess",
    name: "devprocess",
    component: () =>
      import(/* webpackChunkName: "devprocess" */ "../views/DevProcess.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/mycourse",
    name: "mycourse",
    component: () =>
      import(/* webpackChunkName: "mycourse" */ "../views/mycourse.vue"),
    // component: () => (window.location.href = "https://pc.zikaoshu.net/bought"),
  },
  {
    path: "/searchprovinceinfo",
    name: "searchprovinceinfo",
    // component: () => import( /* webpackChunkName: "contact" */ '../views/SearchProvinceInfo.vue')
    component: () => import("../views/SearchPrivinceInfo.vue"),
  },
  {
    path: "/news",
    name: "news",
    // component: () => import( /* webpackChunkName: "contact" */ '../views/SearchProvinceInfo.vue')
    component: () => import("../views/News.vue"),
  },
  {
    path: "*", //全不匹配的情况下，返回404，路由按顺序从上到下，依次匹配。最后一个*能匹配全部，
    component: Home,
  },
];

const router = new VueRouter({
  base: "/",
  routes,
  mode: "history",
  // base: process.env.NODE_ENV == "stage" ? "/official" : "",
  scrollBehavior(to, from, savedPosition) {
    // `to` 和 `from` 都是路由对象
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
router.beforeEach((to, from, next) => {
  // let token = localStorage.getItem('getToken');
  // let token = '';
  console.log(to);
  console.log(from);
  store.commit("setActiveIndex", to.path);

  // if (to.path == '/mycourse' && !token) {
  // 	return;
  // }else{
  // 	next();
  // }

  // if (to.path !== from.path) {
  //   if (to.path !== "/login") {
  //     next();
  //   }
  // }
  next();
});
router.onError(function () {
  console.log("router err");
});
export default router;
