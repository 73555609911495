import ajax from "@/utils/ajax";
// const ENV = process.env.VUE_APP_MODE === 'test' ? 'test' : 'mini1' // (test | refund1 | mini1(官网生产) | www.zikaoshu.xyz);
// const baseUrl = '//test.zikaoshu.vip';//测试环境
// const baseUrl = 'http://refund1.zikaoshu.vip';//测试环境
// const baseUrlServer = `http://${ENV}.zikaoshu.vip`; //测试环境
// const baseUrlServer = `https://${ENV}.zikaoshu.vip/newbaseinfo`; //测试环境
// const baseUrlServer-old = `https://${ENV}.zikaoshu.vip`; //线上测试环境
// const baseUrlServer = `https://www.zikaoshu.xyz`; //线上生产环境
// const baseUrlServer = `https://www.zikaoshu.net`; //线上生产环境
// const baseUrlServer =
//   process.env.VUE_APP_MODE === "stage"
//     ? "https://www.zikaoshu.xyz/official"
//     : "https://www.zikaoshu.net"; //线上生产环境
const baseUrlServer = "";
// const baseUrlLocal = `/api`; //本地代理
const baseUrlLocal = ``; //本地代理
const baseUrl =
  process.env.NODE_ENV === "development"
    ? `${baseUrlLocal}`
    : `${baseUrlServer}`;
// const baseUrl = `${baseUrlServer}`;
// const newBaseUrl = process.env.NODE_ENV === "development" ?
//   `${baseUrlLocal}` :
//   `${baseUrlServer}`;
const baseUserUrl = "/api/course/";
const API = {
  // getB: baseUrl + '/refund/B1',
  // LOGIN: baseUrl + '/ng/refund/refund/login', //用户授权，获取个人信息。
  // ISALLOWAPPLYREFUND: baseUrl + '/ng/refund/refund/isAllowApplyRefund', //押题退款申请时间和资格判断
  // GETEXAMINATIONORDERINFO: baseUrl + '/ng/refund/refund/getExaminationOrderInfo', // 查询用户当前考期押题订单信息
  // UPLOADUSERGRADEIMG: baseUrl + '/refund/uploadUserGradeImg', //上传截图
  // GETMYREFUNDINFO: baseUrl + '/ng/refund/refund/getMyRefundInfo', //查询用户退款申请记录详情信息
  // GETPAGEREFUNDINFO: baseUrl + '/ng/refund/refund/getPageRefundInfo', //分页查询用户退款申请记录信息
  // ADDUSERREFUND: baseUrl + '/ng/refund/refund/addUserRefund', //新增一个用户退款申请记录
  // CANCELUSERREFUND: baseUrl + '/ng/refund/refund/cancelUserRefund', //撤销押题退款申请
  // UPDATEUSERREFUNDINFO: baseUrl + '/ng/refund/refund/updateUserRefundInfo', //修改押题退款申请

  // GETALLPROVINCE: baseUrl + "/newbaseinfo/getAllProvince", //查询所有省份
  GETALLPROVINCE: baseUrl + "/api/official/v1/goods/getAllProvince", //查询所有省份
  // GETALLMAJOR: baseUrl + "/newbaseinfo/getAllMajor", //根据省份和学历查询所有专业
  GETALLMAJOR: baseUrl + "/api/official/v1/goods/getAllMajor", //根据省份和学历查询所有专业
  // GETWEBSITELINK: baseUrl + "/newbaseinfo/getWebsiteLink", //根据省份查询所有外链
  GETWEBSITELINK: baseUrl + "/api/official/v1/goods/findlinkList", //根据省份查询所有外链
  // getStartInfo: baseUrl + "/newbaseinfo/getStartInfo", //获取省考信息
  getStartInfo: baseUrl + "/api/official/v1/goods/getStartInfo", //获取省考信息
  getDateExamination: baseUrl + "api/msc/v1/goods/getDateExamination", //获取省考信息
  getMySubjectGoods: baseUrl + "/trade/getMySubjectGoods", //课程列表
  // gotoLogin: baseUrl + "/officialWebsite/gotoLogin", //获取appidAndsecret
  gotoLogin: baseUrl + "/api/official/v1/goods/gotoLogin", //获取appidAndsecret
  websiteLogin: baseUrl + "/user/websiteLogin", //课程登录
  getCode: "", //获取验证码
  checkSms: "", //检查验证码是否正确
  wxAuth: "/api/auth/wx/token",
  userInfo: "/api/user/v1/user/info?data=true",
  myCourseGoodsList: "/api/trade/v2/getMyCourseGoodsList",
  getModuleParentRecord: "/api/course/v2/getModuleParentRecord",
  getCharpterRecord: "/api/course/v2/getCharpterRecord",
  getCharpters: "/api/course/v2/goods/getCharpters",
  findCharpterVideo: "/api/course/v2/goods/findCharpterVideo",
  commitLearnTime: "/api/course/v2/commitLearnTime",
  getPageBreakingNewsList: "/api/official/v1/goods/getPageBreakingNewsList",
};

// export function login(params) {
//   return ajax({
//     url: API.LOGIN,
//     method: 'post',
//     params
//   })
// }

// function getUserInfo(params) {
//   return ajax({
//     url: API.LOGIN,
//     method: 'post',
//     params
//   })
// }

// function getPageRefundInfo(params) {
//   return ajax({
//     url: API.GETPAGEREFUNDINFO,
//     method: 'post',
//     params
//   })
// }

// function getMyRefundInfo(params) {
//   return ajax({
//     url: API.GETMYREFUNDINFO,
//     method: 'post',
//     params
//   })
// }

// function isAllowApplyRefund(params) {
//   return ajax({
//     url: API.ISALLOWAPPLYREFUND,
//     method: 'post',
//     params
//   })
// }

// function getExaminationOrderInfo(params) {
//   return ajax({
//     url: API.GETEXAMINATIONORDERINFO,
//     method: 'post',
//     params
//   })
// }

// function uploadUserGradeImg(params) {
//   return ajax({
//     headers: {
//       'Content-Type': 'multipart/form-data',
//       // "Content-Type": "application/x-www-form-urlencoded"
//     },
//     url: API.UPLOADUSERGRADEIMG,
//     method: 'post',
//     data: params
//   })
// }

// function addUserRefund(params) {
//   return ajax({
//     url: API.ADDUSERREFUND,
//     method: 'post',
//     // params: {
//     // 	alipayAccount: params.alipayAccount,
//     // 	gradeImgUrl: params.gradeImgUrl,
//     // 	token: params.token
//     // },
//     // data: {
//     // 	orderArr: params.orderArr,
//     // },
//     params
//   })
// }

// function cancelUserRefund(params) {
//   return ajax({
//     url: API.CANCELUSERREFUND,
//     method: 'post',
//     params
//   })
// }

// function updateUserRefundInfo(params) {
//   return ajax({
//     url: API.UPDATEUSERREFUNDINFO,
//     method: 'post',
//     params
//   })
// }

// function getRefundInfo(params) {
// 	return ajax({
// 		url: API.REFUNDINFO,
// 		method: 'post',
// 		params
// 	})
// }

// function getMoreOrder(params) {
// 	return ajax({
// 		url: API.REFUNDINFO,
// 		method: 'post',
// 		params,
// 	})
// }

function getAllProvince(params) {
  return ajax({
    url: API.GETALLPROVINCE,
    method: "post",
    params,
  });
}

function getAllMajor(params) {
  return ajax({
    url: API.GETALLMAJOR,
    method: "post",
    params,
  });
}

function getWebsiteLink(params) {
  return ajax({
    url: API.GETWEBSITELINK,
    method: "post",
    // params,
    data: params,
    header: {
      "Content-Type": "application/json",
    },
  });
}

function getDateExamination(params) {
  return ajax({
    url: API.getDateExamination,
    method: "GET",
    // params,
    data: params,
    header: {
      "Content-Type": "application/json",
    },
  });
}

function getStartInfo(params) {
  return ajax({
    url: API.getStartInfo,
    method: "post",
    params,
  });
}

function getMySubjectGoods(params) {
  return ajax({
    url: API.getMySubjectGoods,
    method: "post",
    params,
  });
}

function getCode(params) {
  return ajax({
    url: API.getCode,
    method: "post",
    params,
  });
}

function checkSms(params) {
  return ajax({
    url: API.checkSms,
    method: "post",
    params,
  });
}

function gotoLogin(params) {
  return ajax({
    url: API.gotoLogin,
    method: "post",
    params,
  });
}

function pcLogin(params) {
  return ajax({
    url: API.websiteLogin,
    method: "post",
    params,
  });
}
/**
 * "grant_type": "wx",
   "scope": "read",
   "code": code,
   "type": "web"
 */

function getWXToken(params) {
  return ajax({
    url: API.wxAuth,
    method: "post",
    params,
    headers: {
      Authorization:
        "Basic d2ViX2FwcDpzcHJpbmctbWljcm9zZXJ2aWNlLWV4YW0tc2VjcmV0",
    },
  });
}
/**
 * @desc 用户信息
 * @returns
 */
function getUserInfo() {
  return ajax({
    url: API.userInfo,
    method: "get",
    // params,
    // headers: {
    //   Authorization:
    //     "Basic d2ViX2FwcDpzcHJpbmctbWljcm9zZXJ2aWNlLWV4YW0tc2VjcmV0",
    // },
  });
}

/**
 * @desc
 * @param {*} params
 */

function getMyCourseGoodsList(params) {
  return ajax({
    url: API.myCourseGoodsList,
    method: "get",
    params,
  });
}

function getModuleParentRecord(params) {
  return ajax({
    url: API.getModuleParentRecord,
    method: "get",
    params,
  });
}

function getCharpterRecord(params) {
  return ajax({
    url: API.getCharpterRecord,
    method: "post",
    params,
  });
}

function getCharpters(params) {
  return ajax({
    url: API.getCharpters,
    method: "get",
    params,
  });
}

function findCharpterVideo(params) {
  return ajax({
    url: API.findCharpterVideo,
    method: "get",
    params,
  });
}

/**
 * @desc 视频签名
 *
 * @export
 * @param {*} params
 * videoId	视频主键
 * @returns
 */
function getPlaysafetoken(params = {}) {
  return ajax({
    url: baseUserUrl + "v2/getPlaysafetoken",
    method: "get",
    params,
    // data: params,
  });
}
/**
 * @desc
 *预览图
 * @export
 * @param {*} params
 * videoId	视频主键
 * @returns
 */
function getVideoPreview(params = {}) {
  return ajax({
    url: baseUserUrl + "v2/getVideoPreview",
    method: "get",
    params,
    // data: params,
  });
}
/**
 * @desc
 *播放sign
 * @export
 * @param {*} params
 * videoId	视频主键
 * @returns
 */
function getPlaysafesign(params = {}) {
  return ajax({
    url: baseUserUrl + "v2/getPlaysafesign",
    method: "get",
    params,
    // data: params,
  });
}

/**
 * @desc 提交视频进度
 * @param {\} params
 * {
 *  chapterName:
    orderNo: 513371798365011968
    subjectId: 84228c380f6259667f7a8ae592730f56
    goodsId: 6a34561ca1d34fbf093cbc27aba598f0
    chapterId: 7862e076989d6e1c423d77700406799d
    parentId: 8c898f205a9806a53bf76f4ac89b09e3
    seeTime: 98
    allTime: 1029
    sort: 7
    realTime: 164
    moduleId: fb7e6b843d2d3a29250a5810e5dbbe06
 * }
 * @returns
 */

function commitLearnTime(params) {
  return ajax({
    url: API.commitLearnTime,
    method: "post",
    params,
    // data: params,
  });
}
/**
 * @desc 查看自考树大事件
 * @param {*} params
 * {
 * current
 * size
 * }
 * @returns
 */
function getPageBreakingNewsList(params) {
  return ajax({
    url: API.getPageBreakingNewsList,
    method: "post",
    params,
    // data: params,
  });
}

export default {
  getPageBreakingNewsList,
  getUserInfo,
  getMyCourseGoodsList,
  getModuleParentRecord,
  getCharpterRecord,
  getCharpters,
  findCharpterVideo,
  getPlaysafetoken,
  getVideoPreview,
  getPlaysafesign,
  commitLearnTime,
  // getRefundInfo,
  // getMoreOrder,
  // isAllowApplyRefund,
  // getExaminationOrderInfo,
  // uploadUserGradeImg,
  // getMyRefundInfo,
  // getPageRefundInfo,
  // addUserRefund,
  // cancelUserRefund,
  // updateUserRefundInfo,
  getAllProvince,
  getAllMajor,
  getWebsiteLink,
  getMySubjectGoods,
  getCode,
  checkSms,
  gotoLogin,
  pcLogin,
  getStartInfo,
  getDateExamination,
  getWXToken,
};
