<template>
  <div class="banner-container">
    <img src="@/assets/images/banner/banner-index-bg.png" alt="banner" />
    <div class="container-of">
      <div class="banner__con" v-if="!isPublicInfo">
        <el-row>
          <el-col>
            <div class="text-left left">
              <p>{{ halfYear }}半年考期</p>
              <p class="last-child">倒计时</p>
            </div>
            <!-- </el-col>
          <el-col :lg="8"> -->
            <h1 class="text-center center">
              <strong>{{ rentDay }}</strong>
              <span>DAYS</span>
            </h1>
            <div class="text-right right">
              <span class="text-left">
                <span>{{ year }}</span>
                <br />
                <span>{{ month }}</span>
                <br />
                <span>{{ date }}</span>
              </span>
            </div>
            <!-- </el-col>
          <el-col :lg="8"> -->
          </el-col>
        </el-row>
      </div>
      <div class="banner__con" v-else>
        <div class="public-info__wrapper">
          <a
            href="http://www.moe.gov.cn/jyb_xxgk/s5743/s5744/202002/t20200227_424894.html"
          >
            <h1>关于2020年4月全国高等教育自学考试延期举行的公告</h1>
            <!-- <img class="public-info" src="@/assets/images/public/public-info.jpg" alt="public-info" /> -->
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request.js'
export default {
  name: 'BannerIndex',
  data() {
    return {
      rentDay: 88,
      isPublicInfo: false,
      halfYear: '上',
      year: '2022',
      month: '04',
      date: '16',
      examDate: '',
    }
  },
  created() {
    // let rentTime = Math.ceil(((new Date('2020 04 11')).getTime() - Date.now()) / (24 * 60 * 60 * 1000));
    // 处理android 与 ios 时间显示兼容性问题
    // let rentTime = Math.ceil((new Date('2020/08/01').getTime() - Date.now()) / (24 * 60 * 60 * 1000));
    // let rentTime = Math.ceil(
    //   (new Date("2021/04/10").getTime() - Date.now()) / (24 * 60 * 60 * 1000)
    // );
    this.computeDay('2022/04/16')
    this.getDateExamination()
  },
  methods: {
    getDateExamination() {
      request
        .getDateExamination()
        .then((res) => {
          console.log(res)
          let examDate = res.data.data.startDate
          examDate = examDate.split(' ')[0]
          examDate = examDate.replace(/-/g, '/')
          this.examDate = examDate
          this.year = examDate.split('/')[0]
          this.month = examDate.split('/')[1]
          this.date = examDate.split('/')[2]
          this.computeDay(this.examDate)
          this.halfYear = this.month <= 6 ? '上' : '下'
        })
        .catch((err) => {
          console.log(err)
        })
    },
    computeDay(date) {
      let rentTime = Math.ceil(
        (new Date(date).getTime() - Date.now()) / (24 * 60 * 60 * 1000),
      )
      // this.rentDay = rentTime
      let preRentTime = rentTime
      rentTime = rentTime + 10
      let timer = setInterval(() => {
        if (rentTime > preRentTime) {
          rentTime = rentTime - 1
          this.rentDay = rentTime >= 0 ? rentTime : 0
        } else {
          clearTimeout(timer)
        }
      }, 50)
    },
  },
}
</script>

<style lang="scss">
.banner-container {
  img {
    width: 100%;
    min-height: 250px;
    object-fit: cover;
  }
}

.container-of {
  width: 100%;
  // height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  // border: 1px solid #FFF;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.banner {
  &__con {
    color: #fff;
    .public-info__wrapper {
      text-align: center;
      h1 {
        display: inline-block;
        padding: 10px 30px;
        font-size: 24px;
        text-align: left;
        color: #fff;
      }
      .public-info {
        width: 70%;
      }
    }
    .el-col {
      position: relative;
    }

    .left {
      position: absolute;
    }

    .center {
      h1 {
        position: relative;
      }
    }

    .right {
      position: absolute;
      right: 0;
      bottom: 0;
      padding-right: 20px;
    }

    .text-left {
      margin-left: 56px;
      text-align: left;
      font-size: 35px;
      letter-spacing: 0.4em;

      p {
        // display: inline-block;
        padding-bottom: 20px;
      }

      p.last-child {
        position: relative;

        &:after {
          display: inline-block;
          position: absolute;
          left: 0;
          bottom: -0.3em;
          content: '';
          width: 1.2em;
          height: 0.2em;
          opacity: 0.3;
          background-color: currentcolor;
        }
      }
    }

    .text-center {
      text-align: center;

      strong {
        font-size: 300px;
        font-weight: normal;
      }

      span {
        display: inline-block;
        padding-left: 30px;
        font-size: 30px;
        // vertical-align: bottom;
      }
    }

    .text-right {
      text-align: right;

      .text-left {
        display: inline-block;
        letter-spacing: 0.2em;

        span {
          display: inline-block;
          position: relative;
          font-size: 30px;
          opacity: 0.7;
          padding-top: 10px;
          padding-bottom: 10px;

          &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            display: inline-block;
            width: 1em;
            height: 2px;
            background-color: grey;
          }

          &:last-child:after {
            width: 200%;
            height: 12px;
            bottom: -6px;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .container-of {
    top: 60%;
  }

  .banner__con {
    .text-center {
      strong {
        font-size: 250px;
        font-weight: normal;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .banner {
    &__con {
      .public-info__wrapper {
        h1 {
          font-size: 18px;
        }
      }
      .text-left {
        font-size: 20px;
        margin-left: 10px;

        p {
          // display: inline-block;
          padding-bottom: 10px;
          letter-spacing: initial;
        }

        p.last-child {
          &:after {
            content: '';
          }
        }
      }

      .text-center {
        strong {
          font-size: 100px;
        }

        span {
          padding-left: 10px;
          font-size: 20px;
        }
      }

      .text-right {
        .text-left {
          span {
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
