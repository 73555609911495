<!--
 * @Author: js
*
 * @Date: 2020-01-09 16:54:48
 * @
# Description:
 -->
<template>
  <div class="about">
    <BannerAbout></BannerAbout>
    <div class="about__subtitle" style="display:none;">
      <h2>关于我们</h2>
      <p>
        南京仰读教育科技有限公司是一家在线教育、图书电商和图书出版的教育科技公司，
        <br />公司成立于2016年3月16日
        <br />公司目前有三块核心业务
      </p>
    </div>
    <CompanyDevProcess style="display: none;"></CompanyDevProcess>
    <div class="seo">
      <h1>自考树介绍</h1>
      <div>
        <p>词条名</p>
        <p>自考树</p>
        <p>logo</p>
        <p>
          <img width="150" src="@/assets/logo.png" alt="logo" srcset />
        </p>
      </div>
      <h1>概述</h1>
      <p>自考树是南京仰读教育科技有限公司旗下的在线教育品牌，专注于成人自考培训领域，为自考生提供课程、题库、图书及考试资讯、组队学习、老师答疑等产品和服务。让自考生花钱少，节约时间，圆大学梦。自考树提供能力提升的课程和活动，打通线上与线下，实现“一座城市，一所自考树大学”。</p>
      <h2>品牌寓意</h2>
      <p>让每一个自考生，都成长为一棵大树！</p>
      <h2>品牌团队</h2>
      <p>陈林，自考树品牌创始人，自考生亲切称呼陈校长。</p>
      <h2>产品体系</h2>
      <div>
        <h3>书籍</h3>
        <p>《21天搞定英语二单词》，《21天通关试卷》系列</p>
        <h3>课程</h3>
        <p>贝拉英语二通关班 ，21天训练营系列</p>
        <h3>工具</h3>
        <p>冲刺蜂：冲刺蜂是针对全国自考生进行的一项组队学习活动，</p>
        <p>南竹林：南竹林是给自考生提供各种学习资料，考试资讯、自考经验的网络社区。</p>
      </div>
      <h2>教育理念</h2>
      <p>每一个学生都是天才！</p>
      <p>教育就是一个灵魂唤醒另一个灵魂！</p>
      <p>让每一个自考生，都成长为一棵大树。</p>
      <h2>品牌哲学</h2>
      <p>把简单的事情天天做好。</p>
      <h2>品牌大事件</h2>

      <p>2016年8月，创立自考树。</p>
      <p>2017年3月， “21天训练营”正式上线。</p>
      <p>2017年11月，贝拉英语二课程正式上线。</p>
      <p>2018年6月，小程序自考树课程正式上线。</p>
      <p>2018年9月，自考英语单词书—《21天搞定英语二单词》正式出版。</p>
      <p>2019年7月，免费题库产品正式上线。</p>
      <p>2019年8月，自考21天通关试卷正式出版。</p>
      <p>2019年9月，自考押题卷产品正式上线。</p>
    </div>
    <!-- 联系我们 -->
    <div style="padding-top: 60px;"></div>
    <Contact></Contact>
  </div>
</template>

<script>
import BannerAbout from "@/components/banner/BannerAbout.vue";
import CompanyDevProcess from "@/components/CompanyDevProcess.vue";
import Contact from "@/views/Contact.vue";
export default {
  name: "About",
  components: {
    BannerAbout,
    CompanyDevProcess,
    Contact
  }
};
</script>

<style lang="scss">
.about {
  // padding-bottom: 150px;
  &__subtitle {
    margin: 0 auto;
    padding: 80px 10px;
    max-width: 1140px;

    h2 {
      font-size: 30px;
      padding: 0.3em 0;
    }

    p {
      font-size: 16px;
      line-height: 1.4em;
    }
  }
  .seo {
    max-width: 1140px;
    margin: auto;
    h1 {
      font-size: 1.5em;
      font-weight: bold;
      padding: 0.5em 0;
    }
    h2 {
      font-size: 1.3em;
      font-weight: bold;
      padding: 0.5em 0;
    }
    h3 {
      font-size: 1.1em;
      font-weight: bold;
      padding: 0.5em 0;
    }
    p {
      line-height: 1.5em;
    }
    h1:first-child {
      padding: 2em 0 1em;
      text-align: center;
    }
  }
}
</style>
