<template>
  <div class="company-dev-process">
    <swiper :options="swiperOption" ref="mySwiper" @someSwiperEvent="callback">
      <swiper-slide>
        <div class="swiper-item">
          <div class="img-wrap">
            <img src="@/assets/images/devprocess/dev-process-01.png" alt="">
          </div>
          <div class="swiper-txt__wrapper">
            <div class="swiper-txt">
              <h2>第一块 图书电商业务</h2>
              <p>
                目前已经成为中国最大的自考图书电商。现在已经布局天猫、京东、当当、拼多多、有赞等主流电商平台。
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <!-- <Store></Store> -->
        <div class="swiper-item">
          <div class="img-wrap">
            <img src="@/assets/images/devprocess/dev-process-02.png" alt="">
          </div>
          <div class="swiper-txt__wrapper">
            <div class="swiper-txt">
              <h2>第二块 在线教育业务</h2>
              <p>
                从2016年开始，公司孵化出 “自考树” 在线教育平台。<br>
                自考树的使命：让每一位自考生，都成长为一棵大树。我们立志5年内成为中国最大的自考在线教育公司。
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="swiper-item">
          <div class="img-wrap">
            <img src="@/assets/images/devprocess/dev-process-03.png" alt="">
          </div>
          <div class="swiper-txt__wrapper">
            <div class="swiper-txt">
              <h2>第三块 图书出版业务</h2>
              <p>
                自考树品牌图书，拥有强大的IP，与电商业务、在线教育业务互相协同，互相促进。计划2020年出版100种图书。<br>
                <!-- 仰读旗下有朗朗图书、自考树等品牌业务。<br> -->
                目前公司员工有近100人，2019年服务70万考生，4年累计服务考生超过200万。
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- <swiper-slide>I'm Slide 4</swiper-slide>
      <swiper-slide>I'm Slide 5</swiper-slide>
      <swiper-slide>I'm Slide 6</swiper-slide>
      <swiper-slide>I'm Slide 7</swiper-slide> -->
      <!-- Optional controls -->
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      <div class="swiper-button-prev" slot="button-prev">
        <!-- <span></span> -->
        <img src="@/assets/images/icon/icon-arrow-left.png" alt="icon">
      </div>
      <div class="swiper-button-next" slot="button-next">
        <img src="@/assets/images/icon/icon-arrow-right.png" alt="icon">
        <!-- <span></span> -->
      </div>
      <!-- <div class="swiper-scrollbar" slot="scrollbar"></div> -->
    </swiper>
  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import {
    swiper,
    swiperSlide
  } from 'vue-awesome-swiper'
  export default {
    name: "CompanyDevProcess",
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        swiperOption: {
          // effect: 'fade',
          centeredSlides: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }
      }
    },
    methods: {
      callback() {}
    }
  }
</script>

<style lang="scss" scoped>
  .company-dev-process {
    padding: 0 15%;
    // max-width: 1440px;
    // margin: 0 auto;

  }

  .swiper-item {
    position: relative;

    // background: url('~@/assets/images/map-chq.png');
    .img-wrap {
      max-width: 810px;
      height: 570px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .swiper-txt__wrapper {
      height: 410px;
      width: 660px;
      border-radius: 6px;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      box-shadow: 0 0 20px #efefef;
      background: #FFFFFF;

      .swiper-txt {
        padding: 0 18%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        h2 {
          font-size: 20px;
          color: #333333;
          padding-bottom: 20px;
        }

        p {
          font-size: 16px;
          line-height: 1.5em;
        }
      }
    }

  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: none;
    right: 0;
    left: auto;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: initial;
    right: 710px;
    left: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    outline: none;
  }

  @media (max-width: 767.98px) {
    .company-dev-process {
      padding: 0 10px;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      background-image: initial;
      left: 0px;
      right: auto;
    }

    .swiper-item {
      .swiper-txt__wrapper {
        width: 80%;
        right: 10%;
      }
    }
  }
</style>
