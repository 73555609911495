<template>
  <div class="edu-online">
    <div class="top_warp">
      <div class="left_content">
        <h1>在线教育—自考树  </h1>
        <p class="introfo">
          自考树，中国第二大学。<br />
          自考树，让自考生花更少的钱，节约更多的时间，去圆自己的大学梦。<br />
          这仅仅是起点。我们未来还将会为你提供能力提升的课程，提供丰富的同学活动，打通线上与线下，实现“一座城市，一所自考树大学”。<br />
        </p>
      </div>
      <div class="right_flex">
        <button @click="goStore" class="btn">去看看<div class="miniprogram-qrcode"><span><img src="@/assets/images/QRcode1.png" alt="miniprogram"></span><span>扫码开始学习</span></div></button>
      </div>
    </div>
    <!-- 中间布局 -->
    <div class="middle_content">
      <van-tabs v-model="active">
        <van-tab title="押题卷">
          <span class="hotCourse">热门课程</span>
          <!-- <div class="course">
						<div class="course-item" v-for="item of courseList" :key="item">
							<span>{{item}}</span>
							<img src="@/assets/images/map/map-gd.png" alt="map">
							<img src="@/assets/images/map/map-js.png" alt="map">
							<img src="@/assets/images/map/map-sc.png" alt="map">
							<img src="@/assets/images/map/map-zj.png" alt="map">
							<img src="@/assets/images/map/map-sd.png" alt="map">
							<img src="@/assets/images/map/map-sh.png" alt="map">
						</div>
					</div> -->
          <el-row class="course">
            <el-col :lg="8" :sm="12" :xs="24" v-for="item of examPaperList" :key="item">
              <div class="course-item">
                <!-- <span>{{item}}</span> -->
                <img v-if="item === 'financialStatement'" src="@/assets/images/eduonline/exampaper/financial-statement.jpg"
                  alt="押题卷">
                <img v-else-if="item === 'historyCurrentModern'" src="@/assets/images/eduonline/exampaper/history-current-modern.jpg"
                  alt="押题卷">
                <img v-else-if="item === 'marxPrinciple'" src="@/assets/images/eduonline/exampaper/marx-principle.jpg"
                  alt="押题卷">
                <img v-else-if="item === 'administrativeLaw'" src="@/assets/images/eduonline/exampaper/administrative-law.jpg"
                  alt="押题卷">
                <img v-else-if="item === 'MzdTheoryIntroduction'" src="@/assets/images/eduonline/exampaper/mzd-theory-introduction.jpg"
                  alt="押题卷">
                <img v-else-if="item === 'thinkingCourse'" src="@/assets/images/eduonline/exampaper/thinking-course.jpg"
                  alt="押题卷">
              </div>
            </el-col>
          </el-row>
        </van-tab>
        <van-tab title="视频课">
          <span class="hotCourse">热门视频</span>
          <el-row class="video">
            <el-col :lg="8" :sm="12" :xs="24" v-for="item of videoList" :key="item">
              <div class="video-item">
                <!-- <span>{{item}}</span> -->
                <img v-if="item === 'englishCommonThree'" src="@/assets/images/eduonline/course/english.jpg" alt="course">
                <img v-else-if="item === 'english'" src="@/assets/images/eduonline/course/english-second.jpg" alt="course">
                <img v-else-if="item === 'englishSecond'" src="@/assets/images/eduonline/course/english-common-three.jpg"
                  alt="course">
                <img v-else-if="item === 'economicLaw'" src="@/assets/images/eduonline/course/economic-law.jpg" alt="course">
                <img v-else-if="item === 'marketing'" src="@/assets/images/eduonline/course/marketing.jpg" alt="course">
                <img v-else-if="item === 'chineseAncientTimesIteration'" src="@/assets/images/eduonline/course/chinese-ancient-times-iteration.jpg"
                  alt="course">
              </div>
            </el-col>
          </el-row>
        </van-tab>
        <van-tab title="免费题库">
          <span class="hotCourse">热门题库</span>
          <el-row class="freeQb">
            <el-col :lg="8" :sm="12" :xs="24" v-for="item of freeQbList" :key="item">
              <div class="freeQb-item">
                <!-- <span>{{item}}</span> -->
                <img v-if="item === 'chineseLanguage'" src="@/assets/images/eduonline/qpool/chinese-language.jpg" alt="map">
                <img v-else-if="item === 'historyCurrentModern'" src="@/assets/images/eduonline/qpool/history-current-modern.jpg"
                  alt="map">
                <img v-else-if="item === 'marxPrinciple'" src="@/assets/images/eduonline/qpool/marx-principle.jpg" alt="map">
                <img v-else-if="item === 'englishSecond'" src="@/assets/images/eduonline/qpool/english-second.jpg" alt="map">
                <img v-else-if="item === 'chineseAncientTimesHistory'" src="@/assets/images/eduonline/qpool/chinese-ancient-times-history.jpg"
                  alt="map">
                <img v-else-if="item === 'literatureIntroduction'" src="@/assets/images/eduonline/qpool/literature-introduction.jpg"
                  alt="map">
              </div>
            </el-col>
          </el-row>
        </van-tab>
        <van-tab title="出版书">
          <span class="hotCourse">热门书籍</span>
          <el-row class="publish">
            <el-col :lg="8" :sm="12" :xs="24" v-for="item of publishList" :key="item">
              <div class="publish-item">
                <img src="@/assets/images/eduonline/publishbook/marx-principle.jpg" alt="book" v-if="item === 'marxPrinciple'">
                <img src="@/assets/images/eduonline/publishbook/english.jpg" alt="book" v-else-if="item === 'english'">
                <img src="@/assets/images/eduonline/publishbook/english-second.jpg" alt="book" v-else-if="item === 'englishSecond'">
                <img src="@/assets/images/eduonline/publishbook/chinese-ancient-times-history.jpg" alt="book" v-else>
              </div>
            </el-col>
          </el-row>
        </van-tab>
      </van-tabs>
    </div>

  </div>
</template>

<script>
  import storeLink from '@/utils/storelink.js'
  export default {
    name: 'EduOnline',
    data() {
      return {
        examPaperList: [
          'historyCurrentModern', 'marxPrinciple', 'financialStatement', 'administrativeLaw', 'MzdTheoryIntroduction',
          'thinkingCourse'
        ],
        videoList: [
          'englishCommonThree', 'english', 'englishSecond', 'marketing', 'economicLaw',
          'chineseAncientTimesIteration',
        ],
        freeQbList: [
          'chineseLanguage', 'englishSecond', 'marxPrinciple', 'chineseAncientTimesHistory', 'historyCurrentModern',
          'literatureIntroduction'
        ],
        publishList: [
          'english', 'englishSecond', 'marxPrinciple', 'chineseAncientTimesHistory',
        ],
        active: 0,
      };
    },
    methods: {
      goStore() {
        storeLink.goStore()
      }
    }
  }
</script>
<style>
  .van-tab {
    padding: 0;
    margin-right: 110px;
    color: #666666;
    font-size: 20px !important;
  }

  .van-tab--active {
    color: #21A854 !important;
    font-size: 20px;

  }

  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0 !important;
  }

  .van-tabs__line {
    width: 120px !important;
    background: #21a854 !important;
  }

  .van-ellipsis {
    overflow: initial !important;
  }

  .van-tabs--line .van-tabs__wrap {
    display: flex;
    justify-content: center;
  }
</style>
<style lang="scss">
  @import "~@/assets/scss/styles.scss";

  .edu-online {
    margin: 0 auto;
    margin-top: 60px;
    max-width: 1140px;
    padding: 0 10px;
    padding-bottom: 110px;
  }

  .top_warp {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 36px;
      color: #333;
    }

    .introfo {
      padding: 20px 0 70px 0;
      letter-spacing: 0.3em;
      line-height: 1.5em;
      color: #999999;
      font-size: 18px;
    }



    .left_content {
      // float: left;
    }

    .right_flex {
      // margin:-30px 0 0 220px;
      padding-left: 20px;
      padding-bottom: 10px;
    }

    .right_flex {
      button {
        // width: 120px;
        // height: 40px;
        // background: #21a854;
        // color: #fff;
        // border: none;
        border-radius: 4px;
        // box-shadow: #086536 0 0 10px;
        cursor: pointer;
        position: relative;
        &:hover {
          .miniprogram-qrcode {
            display: block;
          }
        }

        .miniprogram-qrcode {
          display: none;
          padding: 5px 0;
          position: absolute;
          right: 100%;
          top: 50%;
          width: 150px;
          margin-right: 15px;
          color: #999;
          box-shadow: 0 0 8px rgba(88,88,88,0.7);
          border-radius: 5px;
          background-color: #FFF;
          transform: translateY(-50%);

          img {
            width: 100%
          }

          span+span {
            display: inline-block;
            padding: 5px;
            font-size: 16px;
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  /* 中间布局 */
  .hotCourse {
    display: block;
    font-size: 18px;
    color: #666666;
    margin: 40px 0 30px 0;
  }

  .course,
  .video,
  .freeQb,
  .publish {
    text-align: center;

    .course-item,
    .video-item,
    .freeQb-item,
    .publish-item {
      // float: left;

      // margin-right: 35px;
      margin: 0 auto;
      margin-bottom: 30px;
      width: 300px;
      height: 240px;
      line-height: 260px;
      border: 1px solid #e9e9e9;
      font-size: 46px;
      color: #555;
      position: relative;
      transition: all 0.5s;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        // border-radius: 50%;
        z-index: -1;
        object-fit: contain;
        transition: all .5s;
      }

      &:hover {

        // background: #f3f3f3;
        img {
          transform: scale(1.1);
        }
      }
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }

  }

  @media (max-width: 767.98px) {
    .van-tab {
      margin-right: 10px;

      span {
        font-size: 16px;
      }
    }

    .van-tabs__line {
      width: 4em !important;
    }
  }
</style>
