function goStore() {
  let url = '//llts.tmall.com/shop/view_shop.htm?spm=a230r.1.14.10.5b47178d0KNw6i&user_number_id=389787275'
  window.open(url, '_blank')
}

function goMajor(major) {
  let url = '';
  switch (major) {
    case 'accounting':
      url =
        '//llts.tmall.com/category-1251736982.htm?spm=a1z10.1-b-s.w5003-21554805924.1.260821d98PRIzy&search=y&parentCatId=1251727032&parentCatName=%D7%D4%BF%BC%7C%B1%BE%BF%C6%D7%A8%D2%B5&catName=%BB%E1%BC%C6&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    case 'chineseLanguage':
      url =
        'https://llts.tmall.com/category-1251736983.htm?spm=a1z10.1-b-s.w5003-21554805924.2.260821d98PRIzy&search=y&parentCatId=1251727032&parentCatName=%D7%D4%BF%BC%7C%B1%BE%BF%C6%D7%A8%D2%B5&catName=%BA%BA%D3%EF%D1%D4%CE%C4%D1%A7&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    case 'english':
      url =
        'https://llts.tmall.com/category-1251736988.htm?spm=a1z10.1-b-s.w5003-21554805924.3.260821d98PRIzy&search=y&parentCatId=1251727032&parentCatName=%D7%D4%BF%BC%7C%B1%BE%BF%C6%D7%A8%D2%B5&catName=%D3%A2%D3%EF&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    case 'administrationManagement':
      url =
        'https://llts.tmall.com/category-1251736981.htm?spm=a1z10.1-b-s.w5003-21554805924.4.260821d98PRIzy&search=y&parentCatId=1251727032&parentCatName=%D7%D4%BF%BC%7C%B1%BE%BF%C6%D7%A8%D2%B5&catName=%D0%D0%D5%FE%B9%DC%C0%ED%D1%A7&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    case 'businessAdministration':
      url =
        'https://llts.tmall.com/category-1251736986.htm?spm=a1z10.1-b-s.w5003-21554805924.6.260821d98PRIzy&search=y&parentCatId=1251727032&parentCatName=%D7%D4%BF%BC%7C%B1%BE%BF%C6%D7%A8%D2%B5&catName=%B9%A4%C9%CC%A3%A8%C6%F3%D2%B5%A3%A9%B9%DC%C0%ED&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    case 'legal':
      url =
        'https://llts.tmall.com/category-1251736985.htm?spm=a1z10.1-b-s.w5003-21554805924.7.260821d98PRIzy&search=y&parentCatId=1251727032&parentCatName=%D7%D4%BF%BC%7C%B1%BE%BF%C6%D7%A8%D2%B5&catName=%B7%A8%C2%C9%2F%C2%C9%CA%A6&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    case 'humanResources':
      url =
        'https://llts.tmall.com/category-1251736980.htm?spm=a1z10.1-b-s.w5003-21554805924.8.260821d98PRIzy&search=y&parentCatId=1251727032&parentCatName=%D7%D4%BF%BC%7C%B1%BE%BF%C6%D7%A8%D2%B5&catName=%C8%CB%C1%A6%D7%CA%D4%B4%B9%DC%C0%ED&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    case 'preschoolEducation':
      url =
        'https://llts.tmall.com/category-1251742833.htm?spm=a1z10.1-b-s.w5003-21554805924.9.260821d98PRIzy&search=y&parentCatId=1251727032&parentCatName=%D7%D4%BF%BC%7C%B1%BE%BF%C6%D7%A8%D2%B5&catName=%D1%A7%C7%B0%BD%CC%D3%FD&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    case 'common':
      url =
        'https://llts.tmall.com/category-1251726621.htm?spm=a1z10.1-b-s.w5003-21554805924.5.260821d98PRIzy&search=y&catName=%D7%D4%BF%BC%7C%B9%AB%B9%B2%BF%CE&scene=taobao_shop#bd'
      window.open(url, '_blank')
      break;
    default:
      break;
  }
}
export default {
  goStore,
  goMajor
}
