<template>
  <div class="banner-container">
    <div class="concern_warp">
      <img src="@/assets/images/banner/banner-contact-bg.png" alt="banner" />
      <!-- <div class="modal"> -->
      <div class="container-of">
        <!-- <p class="contact_text">
					<span>联系我们:</span>
				</p> -->
        <div class="expalin_content">
          <p class="contact__email">
            <img src="@/assets/images/icon/icon-email.png" alt="email" />
            <span>邮箱地址：service@zikaoshu.xyz</span>
          </p>
          <p class="contact__addr">
            <img src="@/assets/images/icon/icon-latlng.png" alt="email" />
            <span>公司地址：南京市鼓楼区北祥路67号泛悦城市广场T2写字楼</span>
            <!-- <span>公司地址：南京市鼓楼区中央北路185号</span> -->
          </p>
        </div>
      </div>
      <div class="modal_warp">
        <el-row class="qrlist">
          <el-col :lg="8" :sm="8" :xs="12">
            <img src="../../assets/images/zikaoshu.png" alt="" />
            <p>微信公众号:自考树</p>
          </el-col>
          <el-col :lg="8" :sm="8" :xs="12">
            <img src="../../assets/images/zikaoshu2.png" alt="" />
            <p>微信公众号:自考树课堂</p>
          </el-col>
          <el-col :lg="8" :sm="8" :xs="12">
            <img src="../../assets/images/zikaoshu1.png" alt="" />
            <p>班班微信号:zikaoshu001</p>
          </el-col>
        </el-row>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerContact',
}
</script>

<style lang="scss" scoped>
.container-of {
  top: 20%;
  // text-align: center;
}

.concern_warp {
  // display: flex;
  // align-items: flex-end;
  position: relative;
  // background: url(~@/assets/images/banner/banner-contact-bg.png) no-repeat center center;
  background-size: cover;
  // min-height: 800px;
  // padding: 28% 0;
}

.concern_warp img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}

.contact_text {
  color: #fff;
  font-size: 30px;
  padding-left: 80px;
  text-align: left;
}
.expalin_content {
  padding-top: 60px;
}
.contact__email,
.contact__addr {
  text-align: center;
}

// .contact__email {
// 	padding: 3em 0 2em 7em;
// }

.contact__addr {
  padding-top: 20px;
  margin-left: 150px;
  // padding: 0.8em 0 0 7em;
  // text-align: center;
}

.contact__email img,
.contact__addr img {
  min-width: 0;
  min-height: 0;
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.contact__addr img {
  margin-left: 100px;
}

.contact__email span,
.contact__addr span {
  color: #fff;
  font-size: 28px;
  // vertical-align: middle;
}

// .modal {
//   margin: auto;
//   max-width: 1140px;
// }
.modal_warp {
  // width: 100%;
  // height: 380px;
  // background: #000000;
  background: rgb(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  padding: 6% 0;
  left: 0;
  width: 100%;

  // display: flex;
  // justify-content: space-around;
  // text-align: center;
  .qrlist {
    margin: auto;
    max-width: 1140px;
    // display: flex;
    text-align: center;

    div {
      // width: 210px;
      padding-top: 5%;
    }

    // div:nth-child(2) {
    //   flex: 1;
    // }
  }
}

.modal_warp img {
  width: 130px;
  height: 130px;
  // margin-top: 106px;
  min-height: initial;
}

.modal_warp p {
  margin-top: 20px;
  color: #fff;
  font-size: 18px;
}

@media (max-width: 767.98px) {
  .concern_warp {
    min-height: 600px;

    img {
      height: 600px;
    }

    .modal_warp {
      img {
        width: 140px;
        height: 140px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .contact__email img,
  .contact__addr img {
    min-width: 0;
    min-height: 0;
    width: 30px;
    height: 30px;
  }

  .contact__email span,
  .contact__addr span {
    font-size: 20px;
    // vertical-align: middle;
  }
}
</style>
