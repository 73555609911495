<template>
  <div id="app">
    <el-container>
      <el-header v-if="!isSearchProvinceInfo">
        <Header v-on:isSHowLoginCom="isSHowLoginCom"></Header>
        <!-- <div class="header">
          <WidgetQR></WidgetQR>
          <div class="logo">
            <i class="el-icon-s-fold" v-if="fold" @click="showMenu"></i>
            <i class="el-icon-s-unfold" v-else @click="showMenu"></i>
          </div>
          <div class="menu" v-bind:class="{fold: fold}">
            <div>
              <el-menu :active-text-color="this.$store.state.navActive" default-active="/" class="el-menu-demo" mode="horizontal"
                @select="handleSelect" router>
                <el-menu-item index="/">首页</el-menu-item>
                <el-menu-item index="/province">省份信息</el-menu-item>
                <el-menu-item index="/bookstore">书店</el-menu-item>
                <el-menu-item index="/eduonline">在线教育</el-menu-item>
                <el-menu-item index="/devprocess">发展历程</el-menu-item>
                <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/contact">联系我们</el-menu-item>-->
        <!-- <el-menu-item index="/login" disabled>
                  <div class="login-wrapper" @click="showLogin">
                    <button class="btn btn-login">登录</button>
                  </div>
        </el-menu-item>-->
        <!-- </el-menu>
            </div>
          </div>
        </div>-->
      </el-header>
      <el-main>
        <WidgetQR></WidgetQR>

        <Login v-if="isLogin" v-on:close="hideLoginCom"></Login>
        <!-- <router-link to="/">home</router-link> -->
        <router-view name="default"></router-view>
        <!-- <Home></Home> -->
        <!-- <BasicLayout /> -->
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>-->
    <!-- <router-view /> -->
  </div>
</template>
<script>
import Footer from '@/components/Footer'
import Login from '@/components/Login'
import Header from '@/components/Header'
import WidgetQR from '@/components/WidgetQR'
// import Home from '@/views/Home.vue'
// import BasicLayout from '@/components/BasicLayout'
export default {
  name: 'app',
  components: {
    Footer,
    Login,
    WidgetQR,
    Header,
    // Home,
    // BasicLayout,
  },
  data() {
    return {
      // isLogin: false,
      isSearchProvinceInfo: false,
      fold: false,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin
    },
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.path === '/') {
      // this.$router.push("/bookstore");
      // this.$router.push('/province')
      // this.$router.go(0);
    }
  },
  watch: {
    '$route.path': function (newVal) {
      if (newVal === '/searchprovinceinfo') {
        this.isSearchProvinceInfo = true
      } else {
        this.isSearchProvinceInfo = false
      }
    },
  },
  methods: {
    handleSelect(index) {
      this.showMenu()
      // console.log(index)
      // console.log(indexPath)
      if (index === '/index') {
        this.isLogin = true
        return false
      }
      if (index === '/login') {
        this.isLogin = true
      }
    },
    showLogin() {
      this.showMenu()
      this.isLogin = true
    },
    // hideLoginCom() {
    //   this.isLogin = false;
    // },
    hideLoginCom: function () {
      this.$store.commit('setLogin', false)
    },
    showMenu() {
      this.fold = !this.fold
    },
    isSHowLoginCom(isLogin) {
      this.isLogin = isLogin
    },
  },
}
</script>
<style lang="scss">
@import '~@/assets/scss/styles.scss';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-header {
  margin: 0 auto;
  width: 100%;

  div.header {
    position: relative;
    z-index: 9;
    margin: 0 auto;
    max-width: 1140px;

    // padding-top: 120px;
    .logo {
      padding: 0 10px;
      height: 100px;
      line-height: 100px;
      width: 150px;
      position: absolute;
      text-align: right;
      background: url('~@/assets/images/logo.png') no-repeat left 10px center;
      background-size: 136px 46px;

      i[class^='el-icon-s'] {
        color: #fff;
        font-size: 2em;
        display: none;
      }
    }

    .menu {
      position: absolute;
      // z-index: 9;
      right: 0;
      text-align: right;
    }

    .el-menu-item {
      padding: 0 15px;
      font-size: 16px;
    }

    // .logo {
    //   position: absolute;
    // }

    .el-menu {
      background-color: transparent;
    }

    .el-menu.el-menu--horizontal {
      border: 0;
      padding: 20px 0;
    }

    .el-menu-item:focus,
    .el-menu-item:hover {
      background-color: transparent;
    }

    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background-color: transparent;
      color: $primary;
    }

    .el-menu--horizontal > .el-menu-item {
      color: #fff;
      text-align: left;
    }

    .login-wrapper {
      margin: 0 -10px;
      padding-left: 10px;

      &:hover {
        // border-bottom: 2px solid $primary;
        button {
          color: $primary;
        }
      }
    }
  }
}

// reset style
.el-main {
  // padding: 0;
  margin-top: -100px;
}

button.btn-login {
  width: 62px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  color: inherit;
}

.el-menu-item.is-disabled {
  color: #fff;
  opacity: 1 !important;
  cursor: pointer !important;
}

@media (max-width: 767.98px) {
  .el-header {
    padding: 0 !important;

    div.header {
      background-color: #444;

      .logo {
        background-size: 18% auto;
        width: 100%;
        height: 60px;
        line-height: 60px;

        i[class^='el-icon-s'] {
          display: inline-block;
        }
      }

      .menu {
        padding-top: 60px;
        position: initial;
        display: none;
      }

      .fold {
        display: block;
      }

      .el-menu.el-menu--horizontal {
        padding: 0;
      }
    }

    .el-menu-item {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
