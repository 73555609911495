<!--
 * @Author: js
 * @Date: 2021-10-21 14:39:34
 * @LastEditors: js
 * @LastEditTime: 2022-07-20 16:33:48
 * @Description: desc
 * @FilePath: /zktree_official_website/src/views/Home.vue
-->
<template>
  <div class="home">
    <Login v-if="isLogin" v-on:close="hideLoginCom"></Login>
    <BannerIndex></BannerIndex>
    <ProvinceNews v-if="isShowProvinceNews"></ProvinceNews>
    <SearchInfo></SearchInfo>
    <Store></Store>
    <EduOnline></EduOnline>
    <!-- <swiper :options="swiperOption" ref="mySwiper" @someSwiperEvent="callback"> -->
    <!-- slides -->
    <!--      <swiper-slide>
      </swiper-slide>
      <swiper-slide>
      </swiper-slide> -->
    <!--   <swiper-slide>
      </swiper-slide> -->
    <!-- <swiper-slide>I'm Slide 4</swiper-slide>
      <swiper-slide>I'm Slide 5</swiper-slide>
      <swiper-slide>I'm Slide 6</swiper-slide>
      <swiper-slide>I'm Slide 7</swiper-slide> -->
    <!-- Optional controls -->
    <!-- <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper> -->
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Login from '@/components/Login.vue'
import BannerIndex from '@/components/banner/BannerIndex.vue'
import ProvinceNews from '@/components/index/ProvinceNews.vue'
import SearchInfo from '@/components/index/SearchInfo.vue'
import Store from '@/components/index/Store.vue'
import EduOnline from '@/components/index/EduOnline.vue'
// import Header from '@/components/Header.vue'
// require styles
// import 'swiper/dist/css/swiper.css'
// import {
//   swiper,
//   // swiperSlide
// } from 'vue-awesome-swiper'

export default {
  name: 'home',
  data() {
    return {
      isShowProvinceNews: true,
      // isLogin: false,
      swiperOption: {
        roundLengths: true,
        direction: 'vertical',
        width: window.innerWidth,
        // height : window.innerHeight,
      },
    }
  },
  components: {
    // HelloWorld,
    Login,
    // swiper,
    // swiperSlide,
    BannerIndex,
    ProvinceNews,
    SearchInfo,
    Store,
    EduOnline,
    // Header
  },
  mounted() {
    console.log('this.$route-home')
    console.log(this.$route)
    // this.$router.push({
    //   path: "/province",
    //   query: { status: "init" },
    // });
  },
  watch: {
    $route: {
      handler: (newName, oldName) => {
        console.log(newName)
        console.log(oldName)
      },
    },
  },
  computed: {
    // swiper() {
    // 	return this.$refs.mySwiper.swiper
    // },
    isLogin() {
      return this.$store.state.isLogin
    },
  },
  methods: {
    hideLoginCom() {
      this.isLogin = false
    },
    callback() {},
  },
  // created(){
  //   console.log('--------------||----')
  // },
  // beforeRouteEnter(to, from, next) {
  //   console.log('----------||||=======')
  //   next()
  // }
}
</script>
