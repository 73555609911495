<template>
  <div class="login">
    <div class="login__content">
      <p class="login__close">
        <img
          src="@/assets/images/icon-close.png"
          alt="icon-close"
          @click="close"
        />
      </p>
      <h1>{{ titleTxt }}</h1>
      <template v-if="loginFlag == 'select'">
        <p class="login__subtitle">愿每一位自考生，都成长为一颗大树</p>
        <div class="login__three-title">
          <span></span>
          <span class="txt">请选择登陆方式</span>
          <span></span>
        </div>
        <div class="login__button">
          <div class="login__wechat" @click="goWechatLogin">
            <p class="img-wrap">
              <img src="@/assets/images/icon-wechat.png" alt="icon-wechat" />
            </p>
            <p>微信扫码登录</p>
          </div>
          <!-- 	<div class="login__phone" @click="goPhoneLogin">
						<p class="img-wrap"><img src="@/assets/images/icon-phone.png" alt="icon-phone"></p>
						<p>手机验证登录</p>
					</div> -->
        </div>
      </template>
      <template v-else-if="loginFlag == 'phone'">
        <form class="login__login-phone" action="">
          <div class="login__login-phone--item">
            <input
              type="text"
              v-model="phone"
              placeholder="请输入手机号"
              maxlength="11"
              @focus="showClearImg('phone')"
              @blur="hideImg('phone')"
            />
            <span>
              <img
                v-show="isShowPhoneClearImg"
                src="@/assets/images/icon-clear.png"
                alt="icon-clear"
                @click="clearValue('phone')"
              />
              <button :disabled="disabled" type="button" @click="getSMS">
                {{ smsBtnTxt }}
              </button>
            </span>
            <div class="line-bm"></div>
          </div>
          <div class="login__login-phone--item">
            <input
              type="text"
              v-model="sms"
              placeholder="请输入验证码"
              maxlength="6"
              @focus="showClearImg('sms')"
              @blur="hideImg('sms')"
            />
            <img
              v-show="isShowSMSClearImg"
              src="@/assets/images/icon-clear.png"
              alt="icon-clear"
              @click="clearValue('sms')"
            />
            <div class="line-bm"></div>
          </div>
          <div class="login__login-phone--item login__login-phone--submit">
            <button
              type="button"
              :disabled="submitDisabled"
              @click="phoneLogin"
            >
              登录
            </button>
          </div>
          <div
            class="login__login-phone--other-login"
            @click="switchWechatLogin"
            v-if="!isBindPhone"
          >
            <span>其他登录方式：</span>
            <img src="@/assets/images/icon-wehcat-grey.png" alt="icon-wechat" />
          </div>
          <div class="bind-phone-txt" v-else>
            <p>绑定成功后，下次扫码直接登录</p>
          </div>
        </form>
      </template>
      <template v-else-if="loginFlag == 'wechat'">
        <form class="login__login-wechat" action="">
          <!-- <img src="" alt="wechat-qrcode"> -->
          <div id="login_container-course" class="qrcode"></div>
          <!-- <p>使用微信扫码登录“自考树”</p> -->
          <!-- <div class="login__login-wechat--other-login" @click="switchPhoneLogin">
						<span>其他登录方式：</span>
						<img src="@/assets/images/icon-phone-grey.png" alt="icon-phone">
					</div> -->
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request.js'
import { APPID, REDIRECTURI } from '@/config/index'
export default {
  name: 'Login',
  data() {
    return {
      loginFlag: 'select',
      phone: '',
      sms: '',
      disabled: true,
      submitDisabled: true,
      isShowPhoneClearImg: false,
      isShowSMSClearImg: false,
      smsBtnTxt: '获取验证码',
      titleObj: {
        titleSelect: '欢迎登陆自考树',
        titlePhone: '手机登录',
        titleWechat: '微信登录',
        titlePhoneBind: '绑定手机号',
      },
      titleTxt: '欢迎登陆自考树',
      isBindPhone: false,
    }
  },
  computed: {
    // titleTxt() {
    //   return this.titleObj.titleSelect
    // }
  },
  watch: {
    phone() {
      if (this.phone.trim().length > 0) {
        this.isShowPhoneClearImg = true
      }
      if (this.phone.trim().length === 11) {
        this.disabled = false
      } else {
        this.disabled = true
      }
      this.activeSubmit()
    },
    sms() {
      if (this.sms.trim().length > 0) {
        this.isShowSMSClearImg = true
      }
      this.activeSubmit()
    },
    isBindPhone() {
      if (this.isBindPhone) {
        this.switchPhoneBindTitle()
      } else {
        this.switchPhoneTitle()
      }
    },
  },
  mounted() {
    console.log('this.$route')
    console.log(this.$route)
    let code = this.$route.query.code ? this.$route.query.code : ''
    this.getToken(code)
  },
  methods: {
    wxLogin() {
      var obj = new window.WxLogin({
        // self_redirect:true,
        id: 'login_container-course',
        appid: 'wxfea5b80958658a98',
        scope: 'snsapi_login',
        redirect_uri: 'https://www.zikaoshu.net/mycourse',
        // state: "",
        // style: "black",
        // href: ""
      })
      console.log(obj)
    },
    setWxerwma(appid) {
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = 'http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
      const wxElement = document.body.appendChild(s)
      wxElement.onload = function () {
        var obj = new window.WxLogin({
          id: 'login_container-course', // 需要显示的容器id
          appid: appid, // 公众号appid wx*******
          scope: 'snsapi_login', // 网页默认即可
          redirect_uri: encodeURIComponent(
            // 'https://zikaoshu.net/websiteService/pcLogin',
            'https://www.zikaoshu.xyz/mycourse',
          ), // 授权成功后回调的url
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: 'black', // 提供"black"、"white"可选。二维码的样式
          href: '', // 外部css文件url，需要https
        })
        sessionStorage.setItem('obj', obj)
      }
    },
    close() {
      this.$emit('close')
    },
    goWechatLogin() {
      // let appid = 'wx2fb1b443289908ff' //测试
      // appid: 'wxfea5b80958658a98', //生产
      this.gotoLogin()
      // this.setWxerwma(appid)
      this.loginFlag = 'wechat'
      this.switchWechatTitle()
    },

    //微信登录
    gotoLogin() {
      let params = {}
      request
        .gotoLogin(params)
        .then((res) => {
          console.log('get appid and secret')
          console.log(res)
          // {
          //   appid: "wxfea5b80958658a98",
          //   appsecret: "0e5589902c0bddfd280126c05f712a2a"
          // }
          // let result = res.data.result
          // sessionStorage.setItem('appid', result.appid)
          // sessionStorage.setItem('appsecret', result.appsecret)
          // let obj = new window.WxLogin({
          let params = {
            // self_redirect: true,
            // self_redirect:true,
            id: 'login_container-course',
            // appid: result.appid,
            // appid: 'wx2fb1b443289908ff', //测试
            // appid: 'wxfea5b80958658a98', //生产
            appid: APPID,
            scope: 'snsapi_login',
            redirect_uri: encodeURIComponent(
              // 'https://www.zikaoshu.net/mycourse',
              // 'https://www.zikaoshu.xyz/mycourse',
              // 'https://www.zikaoshu.xyz/province',
              REDIRECTURI,
            ),
            // redirect_uri: encodeURIComponent("https://www.zikaoshu.xyz/mycourse"),
            // redirect_uri: encodeURIComponent("http://192.168.0.196:8080/mycourse"),
            state: Math.ceil(Math.random() * 1000),
            // state: "STATE",
            style: 'black',
            // href: '',
          }
          console.log(params)
          // eslint-disable-next-line no-undef
          let obj = new WxLogin(params)
          console.log('obj-new window.WxLogin')
          console.log(obj)
          // this.setWxerwma(result.appid)
          sessionStorage.setItem('wxloginback', JSON.stringify(obj))
          // sessionStorage.setItem("token",res.data.token);
          //sessionStorage.setItem("userid",res.data.userid);
          // if (res.data.status === 'ok') {
          // 	this.mycourseList = res.data.result;
          // }
        })
        .catch((err) => {
          console.log('gotoLogin-err')
          console.log(err)
        })
    },

    getToken(code) {
      console.log('component-login')
      if (!code) {
        return false
      }
      let params = {
        code: code,
        grant_type: 'wx',
        scope: 'read',
        type: 'web',
      }
      request
        .getWXToken(params)
        .then((res) => {
          console.log(res)
          this.$store.commit('setToken', res)
        })
        .catch((err) => {
          console.error(err)
        })
      // request
      //   .pcLogin(params)
      //   .then((res) => {
      //     console.log('get token')
      //     console.log(res)
      //     sessionStorage.setItem('token', JSON.stringify(res.data.tokenKey))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    },
    goPhoneLogin() {
      this.loginFlag = 'phone'
      this.switchPhoneTitle()
    },
    activeSubmit() {
      if (this.phone.trim().length === 11 && this.sms.trim().length === 6) {
        this.submitDisabled = false
      } else {
        this.submitDisabled = true
      }
    },
    // 输入验证码
    showClearImg(from) {
      console.log(from)
      if (from === 'phone' && this.phone.trim().length > 0) {
        this.isShowPhoneClearImg = true
      } else if (from === 'sms' && this.sms.trim().length > 0) {
        this.isShowSMSClearImg = true
      }
    },
    // 清空value
    clearValue(from) {
      if (from === 'phone') {
        this.phone = ''
      } else {
        this.sms = ''
      }
      this.hideClearImg(from)
    },
    hideImg(from) {
      let timer = setTimeout(() => {
        if (from === 'phone' && this.isShowPhoneClearImg) {
          this.hideClearImg(from)
        } else if (from === 'sms' && this.isShowSMSClearImg) {
          this.hideClearImg(from)
        }
        clearTimeout(timer)
      }, 200)
    },
    hideClearImg(from) {
      if (from === 'phone') {
        this.isShowPhoneClearImg = false
      } else {
        this.isShowSMSClearImg = false
      }
    },

    getSMS() {
      let that = this
      if (!/^1\d{10}$/.test(that.phone)) {
        that.$alert('手机号不正确', 'error')
        return
      }
      // that.getCode();
      let time = 60
      let timer = setInterval(() => {
        time = time - 1
        that.smsBtnTxt = time + 's后重新获取'
        if (!that.disabled) {
          that.disabled = true
        }
        if (time <= 0) {
          clearInterval(timer)
          that.smsBtnTxt = '获取验证码'
          if (/^1\d{10}$/.test(that.phone)) {
            that.disabled = false
          }
        }
      }, 1000)
    },

    // 获取验证码
    // getCode:function(){
    // 	let that = this;
    // 	let params={

    // 	}
    // 	request.getCode(params).then(res => {
    // 		console.log(res)
    // 		// if (res.data.status === 'ok') {
    // 		// 	this.mycourseList = res.data.result;
    // 		// }
    // 	}).catch(err => {
    // 		console.log(err)
    // 	})
    // },
    // 登录
    phoneLogin() {
      let that = this
      if (!/^1\d{10}$/.test(that.phone)) {
        that.$alert('手机号不正确', 'error')
        return
      }
      if (!/^\d{6}$/.test(that.sms)) {
        that.$alert('验证码不合法', 'error')
        return
      }
      that.checkSms()
    },
    // 校验验证码
    checkSms: function () {
      // let that = this;
      // let params = {
      // }
      // request.checkSms(params).then(res => {
      // 		console.log(res)
      // 		// if (res.data.status === 'ok') {
      // 		// 	this.mycourseList = res.data.result;
      // 		// }
      // 	}).catch(err => {
      // 		console.log(err)
      // 	})
    },

    switchWechatLogin() {
      // this.wxLogin();
      this.loginFlag = 'wechat'
      this.switchWechatTitle()
    },
    switchPhoneLogin() {
      this.loginFlag = 'phone'
      this.switchPhoneTitle()
    },
    switchWechatTitle() {
      this.titleTxt = this.titleObj.titleWechat
    },
    switchPhoneTitle() {
      this.titleTxt = this.titleObj.titlePhone
    },
    switchPhoneBindTitle() {
      this.titleTxt = this.titleObj.titlePhoneBind
    },
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/mixins.scss';
@import '~@/assets/scss/variables.scss';

.impowerBox .title {
  display: none;
}

.login {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  text-align: center;

  button {
    cursor: pointer;
  }

  img {
    cursor: pointer;
  }

  &__content {
    width: 400px;
    // height: 320px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__close {
    padding: 20px 20px 10px 0;
    text-align: right;
  }

  h1 {
    font-size: 18px;
    color: #333;
    display: none;
  }

  &__subtitle {
    padding-top: 10px;
    font-size: 14px;
    color: #333;
  }

  &__three-title {
    padding: 40px 0;

    span:first-child {
      display: inline-block;
      width: 80px;
      height: 1em;
      @include line-center;
      vertical-align: middle;
    }

    .txt {
      padding: 0 20px;
      color: #b2b2b2;
      font-size: 12px;
    }

    span:last-child {
      display: inline-block;
      width: 80px;
      height: 1em;
      @include line-center;
    }
  }

  &__button {
    overflow: hidden;
    padding-bottom: 45px;
  }

  &__wechat,
  &__phone {
    // width: 50%;
    // text-align: right;
    // float: left;
  }

  &__phone {
    text-align: left;
  }

  &__wechat p:last-child,
  &__phone p:last-child {
    padding-top: 10px;
    width: 130px;
    text-align: center;
    font-size: 12px;
    color: #b2b2b2;
    cursor: pointer;
  }

  &__wechat p:last-child {
    // display: inline-block;
    margin: auto;
  }

  &__wechat .img-wrap,
  &__phone .img-wrap {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #ccc;
    text-align: center;
    cursor: pointer;
  }

  &__wechat .img-wrap {
    // margin-right: 35px;
  }

  &__phone .img-wrap {
    margin-left: 35px;
  }

  &__wechat img,
  &__wechat .qrcode,
  &__phone img {
    vertical-align: middle;
  }

  // 手机登录
  &__login-phone {
    padding: 20px 30px 50px;

    &--item {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      img {
        width: 14px;
        height: 14px;
      }

      button {
        height: 30px;
        line-height: 32px;
        box-shadow: 0 0 0 1px #eee;
        border-radius: 2px;
        outline: none;
        border: 0;
        appearance: none;
        font-size: 14px;
      }

      button {
        background-color: $primary;
        color: #fff;
      }

      button:disabled {
        background-color: #ffffff;
        color: #b2b2b2;
      }

      span {
        img {
          margin-right: 20px;
          vertical-align: middle;
        }
      }

      input {
        color: #333;
        font-size: 18px;
        outline: none;
        border: 0;
        min-width: 0;
      }

      // input:focus+span img {
      //   display: inline-block;
      // }

      .line-bm {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #eeeeee;
        transition: all 0.3s;
      }

      input:focus + span + div {
        border-bottom-color: #999;
      }

      input:focus + img + div {
        border-bottom-color: #999;
      }

      // input:focus+img {
      //   display: inline-block;
      // }

      input::-ms-webkit-input-placeholder {
        color: #b2b2b2;
        font-size: 16px;
      }

      input::-webkit-input-placeholder {
        font-size: 16px;
        color: #b2b2b2;
      }
    }

    &--item:nth-child(2) {
      margin-top: 10px;
    }

    &--submit {
      margin-top: 40px;
      border: 0;

      button {
        border-radius: 4px;
        width: 100%;
        height: 40px;
        font-size: 18px;
      }

      button {
        background-color: $primary;
        color: #fff;
        box-shadow: 0 0 7px #209c4f;
      }

      button:disabled {
        background-color: #eee;
        color: #b2b2b2;
        box-shadow: initial;
      }
    }

    &--other-login {
      padding-top: 20px;
      text-align: left;
      cursor: pointer;

      span {
        vertical-align: middle;
      }

      img {
        height: 22px;
        width: 26px;
        vertical-align: middle;
      }
    }

    .bind-phone-txt {
      padding-top: 20px;
      color: #b2b2b2;
      font-size: 12px;
    }
  }

  // 微信登录
  &__login-wechat {
    padding: 20px 30px 40px;

    img {
      height: 160px;
      width: 160px;
    }

    p {
      padding-top: 30px;
    }

    &--other-login {
      padding-top: 20px;
      text-align: left;
      cursor: pointer;

      span {
        vertical-align: middle;
      }

      img {
        height: 22px;
        width: 26px;
        vertical-align: middle;
      }
    }
  }

  // 绑定手机号
}
</style>
