<template>
  <div class="store">
    <div class="store__header container-index">
      <h1>书店—朗朗图书</h1>
      <p>

        一家专注自考13年的老店。在自考行业，人人皆知。 <br />
        掌柜是一个爱书之人，也是一枚资深自考生。 <br />
        有幸，他把这两件事结合在了一起。他对自考生的爱，已经融入到骨头里。他说，他这辈子就只做这一件事：让每一位自考生，都成长为一棵大树！
        <!-- 全国最大图书自考电商-朗朗图书专营店 <br />
        根据不同考生的需求，将店铺划分为不同模块，便于学生快速找到所需书籍/资料 <br />
        为帮助自考生通过考试，店铺提供多种类型自考资料。 -->
      </p>
      <div><button class="btn" type="button" @click="goStore">进入店铺</button></div>
    </div>
    <div class="store__classify">
      <swiper :options="swiperOption" ref="mySwiper" @someSwiperEvent="callback">
        <!-- slides -->
        <swiper-slide>
          <!-- <SearchInfo></SearchInfo> -->
          <div class="swiper-item" @click="goMajor('accounting')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professional-accounting.png" alt="store"></div>
            <p>会计专业</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <!-- <Store></Store> -->
          <div class="swiper-item" @click="goMajor('chineseLanguage')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professional-chinese-language.png" alt="store"></div>
            <p>汉语言专业</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item" @click="goMajor('english')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professional-english.png" alt="store"></div>
            <p>英语专业</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item" @click="goMajor('administrationManagement')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professional-administration-management.png" alt="store"></div>
            <p>行政管理专业</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item" @click="goMajor('businessAdministration')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professlonal-business-administration.png" alt="store"></div>
            <p>工商管理专业</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item" @click="goMajor('legal')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professional-legal.png" alt="store"></div>
            <p>法律专业</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item" @click="goMajor('humanResources')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professional-human-resources.png" alt="store"></div>
            <p>人力资源专业</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item" @click="goMajor('preschoolEducation')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professional-preschool-education.png" alt="store"></div>
            <p>学前教育专业</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item" @click="goMajor('common')">
            <div class="img-wrap"><img src="@/assets/images/hotmajor/professional-common.png" alt="store"></div>
            <p>公共课</p>
          </div>
        </swiper-slide>
        <!-- <swiper-slide>I'm Slide 4</swiper-slide>
      <swiper-slide>I'm Slide 5</swiper-slide>
      <swiper-slide>I'm Slide 6</swiper-slide>
      <swiper-slide>I'm Slide 7</swiper-slide> -->
        <!-- Optional controls -->
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev">
          <span></span>
        </div>
        <div class="swiper-button-next" slot="button-next">
          <span></span>
        </div>
        <!-- <div class="swiper-scrollbar" slot="scrollbar"></div> -->
      </swiper>
    </div>
  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import bookLink from "@/utils/storelink.js"
  import {
    swiper,
    swiperSlide
  } from 'vue-awesome-swiper'

  export default {
    name: 'Store',
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        swiperOption: {
          // direction: "vertical"
          // width: "690",
          // slidesOffsetBefore: 690,
          roundLengths: true,
          slidesPerView: 3,
          centeredSlides: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            // type: 'bullets',
            type: 'fraction',
            //type : 'progressbar',
            //type : 'custom',
          },
          // scrollbar: {
          //   el: '.swiper-scrollbar',
          //   hide: true,
          // },
          breakpoints: {
            //当宽度大于等于320
            // 320: {
            //   slidesPerView: 2,
            //   spaceBetween: 10
            // },
            //当宽度大于等于768
            768: {
              slidesPerView: 1,
              width: window.innerWidth,
              spaceBetween: 0,
              slidesOffsetBefore: 0,
              centeredSlides: true,
            },
            //当宽度大于等于1280
            1280: {
              slidesOffsetBefore: 0,
              // slidesOffsetBefore: 350
              slidesPerView: 2,
              // spaceBetween: 30
            }
          }
        }
      }
    },
    methods: {
      callback() {},
      goStore() {
        // location.href = '//llts.tmall.com/shop/view_shop.htm?spm=a230r.1.14.10.5b47178d0KNw6i&user_number_id=389787275'
        bookLink.goStore()
      },
      goMajor(major) {
        bookLink.goMajor(major)
      }
    }
  }
</script>

<style lang="scss">
  @import "~@/assets/scss/styles.scss";

  .store {
    padding-top: 80px;
    padding-bottom: 180px;
    text-align: left;
    background-color: #FCFCFC;

    &__header {
      padding: 0 10px;
    }

    h1 {
      color: #333;
      font-size: 36px;
    }

    p {
      padding: 20px 0 50px;
      font-size: 18px;
      color: #999;
      letter-spacing: 0.3em;
      line-height: 1.5em;
    }


    .store__classify {

      .swiper-item {
        padding: 30px 45px 250px;
        text-align: center;

        p {
          padding-top: 30px;
          font-size: 26px;
          color: #333;
        }
      }

      .img-wrap {

        box-shadow: 0 0 40px rgba(19, 87, 56, 0.1);
        background: transparent;
        border-radius: 14px;

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        img {
          width: 100%;
          border-radius: 14px;
          vertical-align: middle;
          object-fit: cover;
          transition: all .5s;
          max-height: 350px;
        }
      }

      .swiper-pagination {
        top: 450px;
        font-size: 36px;
        color: #CCC;
        font-weight: normal;
      }

      .swiper-button-next,
      .swiper-button-prev {
        outline: none;
        background-image: none;
        top: 550px;
        // bottom:;
        width: 100px;
        height: 100px;
        line-height: 100px;
        border-radius: 50%;
        border: 1px solid $primary;

        span {
          display: inline-block;
          height: 20px;
          width: 20px;
          border: 1px solid;
          border-color: $primary;
        }
      }

      .swiper-button-prev {
        left: 50%;
        // left: 0;
        transform: translateX(-50%);
        margin-left: -120px;

        span {
          position: absolute;
          left: 50%;
          top: 50%;
          border-right-color: transparent;
          border-bottom-color: transparent;
          // transform-origin: center center;
          // transform: rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
          margin-left: 5px;
        }
      }

      .swiper-button-next {
        left: 50%;
        transform: translateX(-50%);
        margin-left: 120px;

        span {
          position: absolute;
          left: 50%;
          top: 50%;
          border-left-color: transparent;
          border-top-color: transparent;
          // transform-origin: center center;
          // transform: rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
          margin-left: -5px;
        }
      }
    }

    swiper-slide {
      width: 600px;
      height: 360px;
    }
  }

  @media (max-width: 1366.98px) {
    .store {
      // padding-top: 30px;
      // padding-bottom: 50px;

      .store__classify {
        // .swiper-item {
        //   padding: 30px 15px 50px;
        //   text-align: center;

        //   p {
        //     padding-top: 20px;
        //     padding-bottom: 20px;
        //   }
        // }

        // .img-wrap {
        //   box-shadow: 0 0 10px #135738;
        // }

        .swiper-pagination {
          top: 360px;
          // top: 300px;
        }

        .swiper-button-prev,
        .swiper-button-next {
          top: 480px;
          // width: 34px;
          // height: 34px;

          // span {
          //   height: 12px;
          //   width: 12px;
          // }
        }

        // .swiper-button-prev span {
        //   margin-left: 3px;
        // }

        // .swiper-button-next span {
        //   margin-left: -3px;
        // }
      }
    }
  }

  @media (max-width: 767.98px) {
    .store {
      padding-top: 30px;
      padding-bottom: 50px;

      .store__classify {
        .swiper-item {
          padding: 30px 15px 50px;
          text-align: center;

          p {
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        .img-wrap {
          box-shadow: 0 0 10px #135738;

          img {
            // width: 100%;
          }
        }

        .swiper-pagination {
          top: 390px;
          // top: 300px;
        }

        .swiper-button-prev,
        .swiper-button-next {
          top: 440px;
          // top: 300px;
          width: 34px;
          height: 34px;

          span {
            height: 12px;
            width: 12px;
          }
        }

        .swiper-button-prev span {
          margin-left: 3px;
        }

        .swiper-button-next span {
          margin-left: -3px;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    .store {
      padding-top: 30px;
      padding-bottom: 50px;

      .store__classify {
        .swiper-item {
          padding: 30px 15px 50px;
          text-align: center;

          p {
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        .img-wrap {
          box-shadow: 0 0 10px #135738;

          img {
            // width: 100%;
          }
        }

        .swiper-pagination {
          top: 360px;
          // top: 300px;
        }

        .swiper-button-prev,
        .swiper-button-next {
          top: 400px;
          // top: 300px;
          width: 34px;
          height: 34px;

          span {
            height: 12px;
            width: 12px;
          }
        }

        .swiper-button-prev span {
          margin-left: 3px;
        }

        .swiper-button-next span {
          margin-left: -3px;
        }
      }
    }
  }
</style>
