/*
 * @Author: js
 * @Date: 2021-10-21 14:39:34
 * @LastEditors: js
 * @LastEditTime: 2022-07-13 10:21:01
 * @Description: desc
 * @FilePath: /zktree_official_website/src/utils/ajax.js
 */
import axios from "axios";
// import $weui from 'weui.js'
import store from "@/store";

// function reGetToken() {
//   location.href =
//     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe482b38cbc619805&redirect_uri=http%3a%2f%2frefund.zikaoshu.vip&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
// }

// 创建axios实例
const ajax = axios.create({
  baseURL: "",
  timeout: 15000, // 请求超时时间
});

// http request 请求拦截器，有token值则配置上token值
ajax.interceptors.request.use(
  (config) => {
    if (!window.navigator.onLine) {
      // $weui.toast("网路不在服务区", 2000)
      return false;
    }
    // if (token) { // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 	config.headers.Authorization = token;
    // }
    // config.headers["Content-type"] = "application/json"
    config.headers = {
      // "Content-Type": "application/x-www-form-urlencoded"
      // "Access-Control-Allow-Origin": "*", //解决cors头问题
      // "Access-Control-Allow-Credentials": "true", //解决session问题
      // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" //将表单数据传递转化为form-data类型
      // "Content-Type": "application/x-www-form-urlencoded" //将表单数据传递转化为form-data类型
      "Content-Type": "application/json", //将表单数据传递转化为form-data类型
      // 'Content-Type': 'multipart/form-data',
      Authorization: !store.state.token
        ? "Basic d2ViX2FwcDpzcHJpbmctbWljcm9zZXJ2aWNlLWV4YW0tc2VjcmV0"
        : `${store.state.token.token_type} ${store.state.token.access_token}`,
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http response 服务器响应拦截器，这里拦截401错误，并重新跳入登页重新获取token
ajax.interceptors.response.use(
  (response) => {
    return response;
    // return Promise.resolve(response)
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 这里写清除token的代码
          // router.replace({
          // 	path: 'login',
          // 	query: {
          // 		redirect: router.currentRoute.fullPath
          // 	} //登录成功后跳入浏览的当前页面
          // })
          localStorage.setItem("token", "");
          store.commit("setToken", "");
          // reGetToken();
      }
    }
    // const httpError = {
    //     hasError: true,
    //     status: error.response.status,
    //     statusText: error.response.statusText
    // }
    // store.commit('ON_HTTP_ERROR', httpError)
    // return Promise.reject(error.response.data)
    return Promise.reject(error.response);
  }
);
export default ajax;
