<template>
  <div class="banner-container">
    <img src="@/assets/images/banner/banner-about-bg.png" alt="banner" />
    <div class="container-of">
      <p class="about__title">
        使命：让每一“位”自考生，都成长成为一棵大树<br />
        愿景：终生致力于建设一所世界顶尖大学
        <br />
        价值观：学生第一，员工第二，股东第三
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerAbout"
};
</script>

<style>
p.about__title {
  font-size: 26px;
  color: #fff;
  text-align: center;
  line-height: 2em;
}

@media (max-width: 767.98px) {
  p.about__title {
    font-size: 18px;
    line-height: 1.5em;
  }
}
</style>
