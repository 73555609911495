<template>
  <div class="footer__wrapper">
    <div class="footer">
      <div class="Login">
        <img src="../assets/images/logo.png" />
      </div>
      <div class="user_wish">
        <span>让每一位自考生，都成长为一棵大树</span>
      </div>
      <!-- imgList -->
      <div class="imgList">
        <div class="user_server" @click="goContact">
          <img src="../assets/images/phone.png" alt />
          <img src="../assets/images/phone_hidden.png" alt />
          <span>联系我们</span>
        </div>
        <div class="user_server" @click="goAbout">
          <img src="../assets/images/about.png" alt />
          <img src="../assets/images/about_hidden.png" alt />
          <span>关于我们</span>
        </div>
        <div class="user_server">
          <img src="../assets/images/weixin.png" alt />
          <img src="../assets/images/weixin_hidden.png" alt />
          <div class="qrcode-yd">
            <span>
              <img src="@/assets/images/qrcode-yd.jpg" alt="qrcode" />
            </span>
            <span>微信扫码关注</span>
          </div>
          <span>关注我们</span>
        </div>
      </div>
      <!-- 下划线 -->
      <div class="user_line"></div>
      <!-- 备案信息 -->
      <p class="put-on-record">
        <a href="http://beian.miit.gov.cn/">{{ICP}}</a>
      </p>
      <!--  版权 -->
      <p class="copy_right">
        自考树课堂是仰读教育旗下专注自考教育的在线学习平台。
        <br />
        2016-{{currentYear}} 南京仰读教育科技有限公司 版权所有
      </p>
    </div>
  </div>
</template>

<script>
import { ICP } from "@/config/index.js";
export default {
  name: "Footer",
  data() {
    return {
      currentYear: "2020",
      ICP: ""
    };
  },
  created() {
    this.ICP = ICP;
    let currentYear = new Date().getFullYear();
    this.currentYear = currentYear >= 2020 ? currentYear : "2020";
  },
  methods: {
    goContact() {
      this.$router.push({
        path: "/contact"
      });
    },
    goAbout() {
      this.$router.push({
        path: "/about"
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/styles.scss";

.Login {
  width: 142px;
  height: 50px;
}

.Login img {
  width: 100%;
  height: 100%;
  margin-top: 60px;
}

.user_wish {
  margin-top: 80px;
  letter-spacing: 3px;
  text-align: left;
  font-size: 14px;
  color: #999999;
}

/* imglist */
.imgList {
  margin-top: 40px;
  text-align: center;

  .user_server {
    display: inline-block;
    // margin-right: 66px;
    padding: 0 7%;
    position: relative;

    .qrcode-yd {
      width: 106px;
      padding: 5px 0;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translate(-50%, -100%);
      background-color: #fff;
      border-radius: 4px;

      span {
        margin: 0;
        padding: 0;
        font-size: 12px;
        color: #666;
      }

      img {
        width: 100px;
        height: 100px;
        border-radius: 0;
      }
    }
  }

  .user_server img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // position: absolute;
    cursor: pointer;
  }

  .user_server > img + img {
    position: absolute;
    display: none;
  }

  .user_server > img + img + div {
    display: none;
  }

  .user_server > img:hover {
    // display: none;
  }

  .user_server:hover img + img {
    display: inline-block;
    // left: 0;
    transform: translateX(-100%);
    z-index: 1;
  }

  .user_server:hover > img + img + div {
    display: inline-block;
  }

  .user_server span {
    display: block;
    color: #999999;
    font-size: 14px;
    margin-top: 10px;
  }
}

/* 下划线 */
.user_line {
  width: 100%;
  height: 2px;
  background: #555;
  margin-top: 30px;
}
// 备案
.put-on-record {
  padding-top: 10px;
  text-align: center;
  a {
    // display: inline-block;
    padding-top: 10px;
    color: #999;
    &:hover {
      color: #bbb;
    }
  }
}
/* 版权 */
.copy_right {
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 2.2em;
  text-align: center;
  font-size: 12px;
  color: #999999;
}
</style>
<style>
.footer__wrapper {
  background: #333333;
  padding-bottom: 1em;
}

.footer {
  max-width: 1140px;
  /* height: 380px; */
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  /* position: absolute; */
  /* bottom: 0; */
}

.el-footer {
  padding: 0;
}
</style>
