<template>
  <div class="widget-qr">
    <img src="@/assets/images/wxLight.png" alt="wxlogin">
    <span class="qr-yd">
      <img src="@/assets/images/qrcode-yd.jpg" alt="qrcode">
      <span>关注“自考树”公众号<br>及时获取自考信息</span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'WidgetQR'
  }
</script>

<style lang="scss" scope="this api replaced by slot-scope in 2.5.0+">
  @import "~@/assets/scss/styles.scss";

  .widget-qr {
    position: fixed;
    right: 10px;
    top: 25%;
    z-index: 9;

    .qr-yd {
      position: absolute;
      right: 50px;
      width: 160px;
      display: none;
      padding: 10px 10px 20px;
      border-radius: 6px;
      background-color: #FFF;
      transform: translateY(-50%);
      // box-shadow: 0 0 10px $primary;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      img {
        width: 100%;
      }

      span {
        display: block;
        text-align: center;
        font-size: 12px;
        line-height: 1.8em;
        color: #999;
      }
    }

    >img {
      width: 40px;

      &:hover+span {
        display: inline-block;
      }
    }
  }
</style>
