<template>
  <div class="header">
    <!-- <WidgetQR></WidgetQR> -->
    <div class="logo">
      <i class="el-icon-s-fold" v-if="fold" @click="showMenu"></i>
      <i class="el-icon-s-unfold" v-else @click="showMenu"></i>
    </div>
    <div class="menu" v-bind:class="{ fold: fold }">
      <div>
        <el-menu
          :active-text-color="this.$store.state.navActive"
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          router
        >
          <!-- <el-menu-item index="/index">首页</el-menu-item> -->
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/province">省份信息</el-menu-item>
          <el-menu-item index="/bookstore">书店</el-menu-item>
          <el-menu-item index="/eduonline">在线教育</el-menu-item>
          <el-menu-item index="/devprocess">发展历程</el-menu-item>
          <el-menu-item index="/about">关于我们</el-menu-item>
          <el-menu-item index="/mycourse">我的课程</el-menu-item>
          <!-- <el-menu-item index="/mycourse"> -->
          <!-- <a href='https://pc.zikaoshu.net/bought' target='_self' class="text-decoration">我的课程</a> -->
          <!-- </el-menu-item> -->
          <!-- <el-menu-item index="/contact">联系我们</el-menu-item> -->
          <!-- <el-menu-item index="/login" disabled class="login-router">
						<div class="login-wrapper" @click="showLogin">
							<button class="btn btn-login">登录</button>
						</div>
					</el-menu-item> -->
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  // props: ["isLogin"],
  data() {
    return {
      // isLogin: false,
      isSearchProvinceInfo: false,
      fold: false,
    }
  },
  computed: {
    navActive() {
      return this.$store.state.navActive
    },
    activeIndex() {
      return this.$store.state.activeIndex
    },
  },
  created: function () {
    // console.log('this.$route')
    // console.log(this.$route)
    // console.log(location.pathname)
    // this.activeIndex = location.pathname
    this.$store.commit('setActiveIndex', location.pathname)
  },
  methods: {
    handleSelect(index) {
      // let token = localStorage.getItem('getToken');
      // let token = '';
      this.showMenu()
      // console.log(index)
      // console.log(indexPath)
      // if (index === "/index") {
      //   this.isLogin = true;
      //   return false;
      // }
      if (index === '/login') {
        // this.isLogin = true;
        // this.$emit("isSHowLoginCom", !this.$store.state.isLogin);
      }
      // if (index == "/mycourse") {
      // 	// alert('请登录')
      // 	// if (token) {
      // 	// 	console.log(token)
      // 	// 	alert("跳转课程页")
      // 	// } else {
      // 	// 	this.$emit("isSHowLoginCom", true);
      // 	// }
      //       // window.location.href='https://pc.zikaoshu.net/bought'
      //       // this.$router.back()
      // }
    },
    showLogin() {
      // this.showMenu();
      // this.isLogin = true;
      // this.$emit("isSHowLoginCom", !this.$store.state.isLogin);
      this.$emit('isSHowLoginCom', true)
    },
    hideLoginCom() {
      this.isLogin = false
    },
    showMenu() {
      this.fold = !this.fold
    },
  },
}
</script>

<style>
.header {
  margin: auto;
}

li:hover button {
  color: white;
}

li div.login-wrapper:hover button {
  color: #fff;
}

li button:hover {
  background-color: transparent;
}

div.header div.login-wrapper:hover button.btn {
  color: #fff;
}

.login-router {
  position: relative;
  /* border: 1px solid red; */
  width: 0;
}

.login-router .login-wrapper {
  position: absolute;
  z-index: 2;
}
.text-decoration {
  text-decoration: none;
}
</style>
