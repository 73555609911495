<template>
  <div class="search-info container-index">
    <div class="search-info__con">
      <h1>信息查询</h1>
      <p>
        <span>自考信息</span>
        <span>&nbsp;|&nbsp;</span>
        <span>考生查询</span>
        <span>&nbsp;|&nbsp;</span>
        <span>成绩查询</span>
      </p>
      <div class="subtitle">
        <el-row>
          <el-col
            :xs="6"
            :sm="8"
            :md="9"
            :lg="10"
            class="span col-lg-4 col-md-4 col-sm-3"
          ></el-col>
          <el-col
            :xs="12"
            :sm="8"
            :md="6"
            :lg="4"
            class="span col-lg-3 col-md-4 col-sm-6"
          >
            热门地区
          </el-col>
          <el-col
            :xs="6"
            :sm="8"
            :md="9"
            :lg="10"
            class="span col-lg-4 col-md-4 col-sm-3"
          ></el-col>
        </el-row>
      </div>
      <el-row class="province">
        <template v-for="item of provinces">
          <el-col
            :xs="12"
            :sm="8"
            :md="6"
            :lg="6"
            :xl="6"
            class="col-sm-6 col-md-6 col-lg-4 col-xl-3"
            :key="item.id"
          >
            <div class="province-item" @click="getProvinceInfo(item)">
              <img
                src="@/assets/images/map/map-gd.png"
                alt="map"
                v-if="item.provinceName === '广东省'"
              />
              <img
                src="@/assets/images/map/map-js.png"
                alt="map"
                v-else-if="item.provinceName === '江苏省'"
              />
              <img
                src="@/assets/images/map/map-sc.png"
                alt="map"
                v-else-if="item.provinceName === '四川省'"
              />
              <img
                src="@/assets/images/map/map-zj.png"
                alt="map"
                v-else-if="item.provinceName === '浙江省'"
              />
              <img
                src="@/assets/images/map/map-sd.png"
                alt="map"
                v-else-if="item.provinceName === '山东省'"
              />
              <img
                src="@/assets/images/map/map-sh.png"
                alt="map"
                v-else-if="item.provinceName === '上海市'"
              />
              <img
                src="@/assets/images/map/map-anh.png"
                alt="map"
                v-else-if="item.provinceName === '安徽省'"
              />
              <img
                src="@/assets/images/map/map-hb.png"
                alt="map"
                v-else-if="item.provinceName === '湖北省'"
              />
              <img
                src="@/assets/images/map/map-chq.png"
                alt="map"
                v-else-if="item.provinceName === '重庆市'"
              />
              <img
                src="@/assets/images/map/map-hn.png"
                alt="map"
                v-else-if="item.provinceName === '河南省'"
              />
              <span>{{ item.provinceName }}</span>
            </div>
          </el-col>
        </template>
      </el-row>
      <div class="get-all">
        <button class="btn" @click="getAllProvince">查看全部</button>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request.js'
export default {
  name: 'SearchInfo',
  data() {
    return {
      provinces: [
        // '广东省', '江苏省', '四川省', '浙江省', '山东省', '上海市', '安徽省', '湖北省', '重庆市', '河南省'
      ],
    }
  },
  created() {
    this.getProvinceInfo()
  },
  methods: {
    getProvinceInfo(province) {
      // let code;
      // let params = null;
      // console.log(province)
      if (province) {
        this.$store.commit('setProvinceInfo', province)
        localStorage.setItem('provinceInfo', JSON.stringify(province))
        this.$router.push({
          path: '/searchprovinceinfo',
        })
        // params = {
        //   provinceCode: code
        // }
        // this.provinceAll.some(item => {
        //   if (item.name.indexOf(province) != -1) {
        //     code = item.code
        //     request.getWebsiteLink(params).then(res => {
        //       console.log(res);
        //     }).catch(err => {
        //       console.log(err)
        //     })
        //     return true
        //   }
        // })
      } else {
        // let loadingInstance = this.$loading.service({
        //   fullscreen: true
        // });
        // let provinces = ['广东', '江苏', '四川', '浙江', '山东', '上海', '安徽', '湖北', '重庆', '河南']
        let provinces = [
          '广东',
          '江苏',
          '四川',
          '浙江',
          '山东',
          '上海',
          '安徽',
          '重庆',
        ]
        request
          .getWebsiteLink({ provinceName: '' })
          .then((res) => {
            // console.log(res);
            // loadingInstance.close()
            if (res.data.status === 'ok') {
              let areasProvinceInfoAll = res.data.result
              let hotProvinceInfo = []
              areasProvinceInfoAll.sort((a, b) => {
                return a.id - b.id
              })

              areasProvinceInfoAll.map((item) => {
                if (provinces.includes(item.provinceName)) {
                  item.provinceName =
                    item.provinceName === '上海' ||
                    item.provinceName === '天津' ||
                    item.provinceName === '北京' ||
                    item.provinceName === '重庆'
                      ? item.provinceName + '市'
                      : item.provinceName === '内蒙古' ||
                        item.provinceName === '黑龙江'
                      ? item.provinceName
                      : item.provinceName + '省'
                  hotProvinceInfo.push(item)
                }
              })
              // areasSet = new Set(areasArr);
              // areas = Array.from(areasSet)
              // // console.log(areasSet)
              // // console.log(areas)
              // areas.map(item => {
              //   areaProvinceInfo = []
              //   areasProvinceInfoAll.map(subItem => {
              //     if (item === subItem.area) {
              //       areaProvinceInfo.push(subItem)
              //     }
              //   })
              //   areasProvinceInfo.push(areaProvinceInfo)
              // })
              this.provinces = hotProvinceInfo
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    getAllProvince() {
      this.$router.push({
        path: '/province',
      })
    },
  },
}
</script>

<style lang="scss">
// @import "~@/assets/scss/mixins.scss";
@import '~@/assets/scss/styles.scss';

.search-info {
  padding-top: 80px;
  padding-bottom: 180px;
  background-color: #fff;
  min-width: 320px;
  text-align: center;
}

.search-info__con {
  h1 {
    font-size: 36px;
    color: #333;
  }

  p {
    margin-top: 20px;
    font-size: 18px;
    color: #666;
  }

  .subtitle {
    margin-top: 60px;
    padding: 0 10px;

    .el-row {
    }

    .span:first-child,
    .span:last-child {
      display: inline-block;
      // width: 400px;
      height: 1em;
      @include line-center;
    }

    .span:nth-child(2) {
      display: inline-block;
      padding: 0 40px;
      font-size: 16px;
      color: #999;
    }
  }

  .province {
    padding-top: 50px;
    // display: flex;
    // justify-content: space-between;

    .province-item {
      // float: left;
      // margin-right: 17.5px;
      // margin-left: 17.5px;
      // padding: 0 17.5px;
      margin: 0 auto;
      margin-bottom: 30px;
      width: 258px;
      width: 200px;
      // width: 100%;
      // height: 258px;
      height: 200px;
      // width: 100%;
      // padding: 50% 0;
      // line-height: 260px;
      line-height: 200px;
      border-radius: 50%;
      border: 1px solid #e9e9e9;
      font-size: 36px;
      color: #555;
      position: relative;
      transition: all 0.5s;

      img {
        position: absolute;
        top: 0;
        top: 5%;
        left: 0;
        left: 5%;
        height: 100%;
        height: 90%;
        width: 100%;
        width: 90%;
        border-radius: 50%;
        // z-index: -1;
        z-index: 0;
        object-fit: contain;
      }

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        // box-shadow: 0 0 15px $primary;
        box-shadow: 0 0 15px rgba(88, 88, 88, 0.3);
        transform: scale(1.05);
      }
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .get-all {
    padding-top: 20px;

    button {
    }
  }
}

@media (max-width: 767.98px) {
  .search-info {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .search-info__con .province .province-item {
    width: 150px;
    height: 150px;
    line-height: 150px;
  }
}
</style>
