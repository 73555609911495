/*
 * @Author: js
 * @Date: 2021-10-21 14:39:34
 * @LastEditors: js
 * @LastEditTime: 2022-07-21 14:04:58
 * @Description: desc
 * @FilePath: /zktree_official_website/src/store/index.js
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    // token: {"access_token":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImJhZWwta2V5LWlkIn0.eyJsb2dpblR5cGUiOiJXWCIsInVzZXJfbmFtZSI6Im9EZGx4dnhjbldoQ1JxWWxJX0VuTDBHbzlIbUkiLCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwidGVuYW50Q29kZSI6InprdHJlZSIsImV4cCI6MTY1ODE5NzAxOSwianRpIjoiYjY3OTQyNmQtNzlhZi00Zjk2LTk5NzYtNzAyMDcwOGNkNTM1IiwiY2xpZW50X2lkIjoid2ViX2FwcCJ9.B8bKj0p5CZZokY8VE5msFCFT9X9vG30kqgKBrSwx9XNpXQdiOTXo_j4rF87QdTW5oiBZ5G0IXJVp5xbhqImawxW4yQPiI0k2YKUEf8YafZHfSFGfm_CmW-xvQdzqum-Tti8qdEEdy6olXUClfWCl8c1mrTZwrP24egvfCp-iuOP1jEkReR_fv0uhr_Vj9F-GBDRg--8SAWdqwRJ46Qt8rpzJOTJys01wPA6o8DxsaXcXlcsDCaC6-zngLGyxOyFl1DXtLBsYr895msX_sr_IidttT3myH21q-bOWhhjUL8BSIP85Nv1qr1ADJ0LUEeyS5rJAwXmpMGzRIOX8KPDtYw","token_type":"bearer","refresh_token":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImJhZWwta2V5LWlkIn0.eyJsb2dpblR5cGUiOiJXWCIsInVzZXJfbmFtZSI6Im9EZGx4dnhjbldoQ1JxWWxJX0VuTDBHbzlIbUkiLCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwiYXRpIjoiYjY3OTQyNmQtNzlhZi00Zjk2LTk5NzYtNzAyMDcwOGNkNTM1IiwidGVuYW50Q29kZSI6InprdHJlZSIsImV4cCI6MTY2MDcwMjYxOSwianRpIjoiNTViMTdjMjgtZjlhOS00ZWVlLThkNTAtYTdiZmYyOTA0NDMzIiwiY2xpZW50X2lkIjoid2ViX2FwcCJ9.XDIzKtsDJfgh2e20xxCcYBGZ1XgLkGCtDFI2-eQYZcW1NFVBbGJSu8VfFJeyL6xNi4ZnPro2JHZFh79vBhZwSLeDaPJosVdCrih9-xdfGrkKAkfnbPbItI-sOHJn0PEFHUBZ60cfXSXlX_dwQkAM2mC2yKjmH6_wlroPg6LWia0ziMp0i016g2sgyiVnmUnV9OKTZy3k_K-n5zXGtLcfiD6sfIiFcXvQlfDwQPjYooChzpFkhiM2qR2ViYn3BFMhQxiOTdXWzMRKX6WvnGAZzi8qo4CCMWDSyAwDTlTHkQRxWgwmedsJ1bhVgTOj2N5Au-ZGNdfe3zqWpsVJFydPoQ","expires_in":86399,"scope":"read write","loginType":"WX","tenantCode":"zktree","jti":"b679426d-79af-4f96-9976-7020708cd535"},
    DEBUG: false,
    navActive: "#21a854",
    activeIndex: "/index",
    provinceInfo: "",
    isLogin: false,
    htmlCon: "",
    userInfo: "",
    news: "",
  },
  mutations: {
    setNews(state, news) {
      state.news = news;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    setProvinceInfo(state, provinceInfo) {
      state.provinceInfo = provinceInfo;
    },
    setActiveIndex(state, activeIndex) {
      state.activeIndex = activeIndex;
    },
    setHtmlCon(state, htmlCon) {
      state.htmlCon = htmlCon;
    },
  },
  actions: {},
  modules: {},
});
